import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import getChartOptions from './ChartOption';

export default function Charts({title="",type,XAxis,YAxis,plotOptions,seriesData,showLegend=true,pointFormatter,showPercentage=false,pointStart='',height = "200px"} : any) {

const chartOptions = getChartOptions(type, {
    title,
    height,
    XAxis,
    YAxis,
    plotOptions,
    pointFormatter,
    seriesData,
    showLegend,
    pointStart
  })

  return (
    <>
      <React.Suspense >
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        {
        showPercentage && <div className="center-label" style={{ position: 'absolute', top: '60%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <h6 className="text-black fw-semibold"> {seriesData.find((data:any )=>data.name !== 'Blank').y} %</h6>
      </div>
       }
      </React.Suspense>
    </>
  );
}

