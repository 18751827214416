import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setPageTitle } from '../../store/commonSlice';
import DynamicMeta from '../../component/DynamicMeta';
import { changePassword } from '../../services/authService';
import { ButtonSpinner } from '../../component/Button';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { FaEye, FaEyeSlash } from "react-icons/fa";

function ChangePassword({ activeTab }) {
    const dispatch = useAppDispatch();

    // Set page title using useEffect to avoid calling dispatch during rendering
    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Sign In"));
    }, [dispatch]);

    const { loader } = useSelector(({ common }) => common);
    const [showPassword, setShowPassword] = useState(false);

    const defaultValues = {
        current_password: '',
        new_password: '',
        new_password_confirmation: ''
    };
    const [detail, setDetails] = useState(defaultValues);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrorMessage("");
        if (detail?.current_password === "") {
            setErrorMessage('Please enter current password');
            return;
        }
        if (detail?.new_password === "") {
            setErrorMessage('Please enter new password');
            return;
        }
        if (detail?.current_password === detail?.new_password) {
            setErrorMessage('Old password and current password should be different');
            return;
        }
        if (detail?.new_password_confirmation === "") {
            setErrorMessage('Please enter confirm password');
            return;
        }
        if (detail?.new_password_confirmation !== detail?.new_password) {
            setErrorMessage('New password  and  confirm password');
            return;
        }
        setErrorMessage("")
        dispatch(changePassword(detail))
            .then((res) => {
                setDetails(defaultValues);
            })
            .catch((error) => {
                setErrorMessage(error?.response?.data?.message);
            });
    };


    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
        setDetails((oldDetail) => ({
            ...oldDetail,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };


    return (
        <div className={`tab-pane fade ${activeTab === 'change-password' ? 'show active' : ''}`} id="change_password_tab" role="tabpanel" aria-labelledby="change_password_tab-tab">
            <DynamicMeta />
            <div className="col-lg-12">
                <div className="">
                    <div className="">
                        <div className="row">
                            <form onSubmit={handleSubmit}>

                                <div className="row gx-3">
                                    <div className="col-lg-4 col-sm-6">
                                        {(errorMessage) && <p className='text-danger'>{errorMessage}</p>}

                                        <div className="mb-4">
                                            <label className="text_purple2 font-s-12 fw-semibold mb-1" >Current Password</label>
                                            <input className="form-control bg-transparent" type="password" name="current_password" placeholder="Current Password" value={detail?.current_password} onChange={handleOnChange} />
                                        </div>

                                        <div className="mb-4" style={{ position: "relative" }}>
                                        <label className="text_purple2 font-s-12 fw-semibold mb-1" >New Password</label>
                                            <input type={showPassword ? 'text' : 'password'} name="new_password" className="form-control bg-transparent form-control-user" id="floatingPassword"
                                                placeholder="New Password" value={detail?.new_password} onChange={handleOnChange} />
                                            <a  onClick={handleTogglePassword} className="input-suffix text-muted" title={showPassword ? "Hide Password" : "View Password"} style={{ position: "absolute", top: "28px", right: "5px" }}>
                                                {detail?.new_password !== "" && (showPassword ? <FaEyeSlash /> : <FaEye />)}
                                            </a>
                                        </div>
                                        <div className="mb-4">
                                            <label className="text_purple2 font-s-12 fw-semibold mb-1" >Confirm Password</label>
                                            <input className="form-control bg-transparent form-control-user" name="new_password_confirmation" type="password" placeholder="Confirm Password" value={detail?.new_password_confirmation} onChange={handleOnChange} />
                                        </div>
                                        <button type="submit" className="btn btn-primary-horizon" disabled={loader} title='Update'><ButtonSpinner /> Update</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ChangePassword;