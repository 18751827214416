import { getRegulations } from '../../services/datasetService';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import React, { useEffect, useMemo, useState } from 'react';
import Pagination from "react-js-pagination";
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ShimmerTable } from 'react-shimmer-effects';
import DataNotFound from '../DataNotFound';
import { updateFilter } from '../../store/commonSlice';

function RegulationRow({ pagination = true }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const [list, setList] = useState({ data: [], current_page: 1, per_page: 10 });
  const [activePage, setActivePage] = useState(1);
  const { regulations } = useSelector(({ dataset }) => dataset);
  const { filter, refresh } = useSelector(({ common }) => common);
  const { company } = useSelector(({ company }) => company);
  const query = useMemo(() => ({
    companyId: filter?.company?._id || companyId || '', year: filter?.year || '', page: activePage, limit: 10
  }), [dispatch,filter, companyId, activePage])
  useEffect(() => {
    dispatch(getRegulations(query));
  }, [dispatch, refresh, activePage]);

  useEffect(() => {
    setActivePage(1)
  }, [])

  useEffect(() => {
    setList(regulations.data);
  }, [regulations]);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber)
  }
  return (
    <section className="mt-5" id="regulations">
      <div className="row">
        {
          regulations?.loading ? <ShimmerTable row={10} col={7} /> :
          <div className="card card-border mb-4 table_layout2">
          <div className="card-header card-header-action border-bottom-0 table_header p-2"><h5>Regulations</h5></div>
          <div className="position-relative">
            <div className="col-lg-12">
              <div className="table-responsive">
                <table className="table table-bordered list-view">
                  <thead>
                    <tr>
                      <th>S.N.</th>
                      <th>Regulation Title</th>
                      <th>Regulation Summary</th>
                      <th>Issuer authority</th>
                      <th>Geography</th>
                      <th>Issue date</th>
                      <th>Regulation Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      list?.data?.map((reg) => {
                        return (
                          <tr>
                            <td>{reg?.regulation_id}</td>
                            <td> <Link to={reg?.link}>{reg?.title}</Link></td>
                            <td>{reg?.description}
                               {  reg?.taxonomy?.map((taxo)=><span className="badge badge-soft-primary">{taxo}</span>)} 
                              </td>
                            <td>{reg?.issuer_authority}</td>
                            <td>{reg?.geography}</td>
                            <td>{reg?.posted_on}</td>
                            <td>{reg?.regulation_status}</td>
                          </tr>
                        )
                      })
                    }
 {
                        (regulations.data?.length <= 0 && !regulations?.loading) && <tr ><td colSpan={6}><DataNotFound title="No results" description="Unfortunately, no related regulations could be found." /></td></tr>
                      }

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      <div className="row text-end">

        {
          (!pagination && (regulations?.data?.length) > 0) && <p className='text-right text-info text-decoration-underline'
          onClick={()=>{
            dispatch(updateFilter({ ...filter, company: { _id: company?.data?._id, name: company?.data?.name } }))
            navigate(`/regulation-list`);
          }} >View All Regulations </p>
        }
      </div>
      {
        ((list?.data?.length) > 0 && pagination) &&

        <div className='mt-4'>

          <Pagination
            activePage={activePage}
            itemsCountPerPage={list.per_page}
            totalItemsCount={list?.data?.length}
            pageRangeDisplayed={5}
            itemClass="page-item"
            linkClass="page-link"
            onChange={handlePageChange}

          />
        </div>
      }
    </section>
  );
};

export default RegulationRow;
