import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { updatePassword } from "../../services/authService";
import { setPageTitle } from "../../store/commonSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ButtonSpinner } from "../../component/Button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { toast } from "react-toastify";

const UpdatePasswordForm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { token, email } = useParams();


    // Set page title using useEffect to avoid calling dispatch during rendering
    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Sign In"));
    }, [dispatch]);

    const { loader  } = useSelector(({ common }) => common);

    const defaultValues = {
        email: email,
        token: token,
        password: '',
        password_confirmation: ''
    };
    const [detail, setDetails] = useState(defaultValues);
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (detail?.password === "") {
            toast.error('Please enter new password')
            return;
        }

        if (detail?.password_confirmation === "") {
            toast.error('Please enter confirm password')
            return;
        }
        if (detail?.password_confirmation !== detail?.password) {
            toast.error('New password  and  confirm password is not match')
            return;
        }
        dispatch(updatePassword({ ...detail, email: email }))
            .then((response) => {
                toast.success('Password updated successfully')
                setDetails(defaultValues);

                navigate('/sign-in')
            }).catch((error) => {
                toast.error('Something went wrong')
            });


    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
        setDetails((oldDetail) => ({
            ...oldDetail,
            [name]: type === 'checkbox' ? checked : value
        }));
    };


    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="form-label-group mb-4">
                    <h4 className="my-0">Update Password</h4>
                </div>
                

                <div className="form-floating mb-4" style={{ position: "relative" }}>
                    <input type={showPassword ? 'text' : 'password'} name="password" className="form-control" id="floatingPassword"
                        placeholder="New Password" value={detail?.password} required onChange={handleOnChange} />
                    <Link to="#" onClick={handleTogglePassword} className="input-suffix text-muted" title={showPassword ? "Hide Password" : "View Password"} style={{ position: "absolute", top: "16px", right: "5px" }}>
                        {detail?.password !== "" && (showPassword ? <FaEyeSlash /> : <FaEye />)}
                    </Link>
                    <label htmlFor="floatingPassword">New Password</label>
                </div>
                <div className="form-floating mb-4" style={{ position: "relative" }}>
                    <input type={'password'} name="password_confirmation" className="form-control" id="floatingPassword"
                        placeholder="Confirm Password" value={detail?.password_confirmation} required onChange={handleOnChange} />
                    <label htmlFor="floatingPassword">Confirm Password</label>
                </div>
                <div className="mb-4">
                    <button className="as_info_btn  btn-uppercase btn-block" disabled={loader} type="submit"> <ButtonSpinner />Update Password</button>
                </div>
            </form>
        </>
    );
};

export default UpdatePasswordForm;
