
import React from "react";
import DynamicMeta from "../../component/DynamicMeta";
import Layout from "../../layouts/dashboard/Layout";
import TopEsgStories from "../../component/TopEsgStories";

function Region() {
    return (
        <>
            <DynamicMeta />
            <Layout >
                <div className="hk-pg-wrapper pb-10">
                    <div className="container-fluid px-4">
                    <div className="hk-pg-body pt-2 pb-14">
                    <section>
                        <div className="row">
                            <div className="col-12">
                                <div className="companies_filter border bg-info bg-opacity-10 rounded p-4">
                                    <div className="col-12">
                                        <h5 className="text-black fw-semibold mb-3">Filter Company By</h5>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0">
                                            <h6 className="fw-semibold text-black">Company Name</h6>
                                            <select className="form-select border fw-semibold text-black">
                                                <option selected>Company A</option>
                                                <option value="1">Company B</option>
                                                <option value="2">Company C</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0">
                                            <h6 className="fw-semibold text-black">Region</h6>
                                            <select className="form-select border fw-semibold text-black">
                                                <option selected>Asia-Pacific</option>
                                                <option value="1">Company B</option>
                                                <option value="2">Company C</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0">
                                            <h6 className="fw-semibold text-black">Country</h6>
                                            <select className="form-select border fw-semibold text-black">
                                                <option selected>India</option>
                                                <option value="1">Company B</option>
                                                <option value="2">Company C</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0">
                                            <h6 className="fw-semibold text-black">Sector</h6>
                                            <select className="form-select border fw-semibold text-black">
                                                <option selected>Banking</option>
                                                <option value="1">Company B</option>
                                                <option value="2">Company C</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0">
                                            <h6 className="fw-semibold text-black">Year</h6>
                                            <select className="form-select border fw-semibold text-black">
                                                <option selected>2023</option>
                                                <option value="1">2024</option>
                                                <option value="2">2025</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-6 col-12 mt-3 mt-lg-0">
                                            <button className="btn company_filter_button w-100">ANALYZE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="companies_filter border bg-info bg-opacity-10 rounded p-4">
                                    <div className="col-12">
                                        <h5 className="text-black fw-semibold mb-3"> Monitors</h5>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg">
                                            <button className="companies_monitors_button w-100">Patents</button>
                                        </div>
                                        <div className="col-lg col-md-4 col-sm-6 mt-3 mt-lg-0">
                                            <button className="companies_monitors_button w-100">Deals</button>
                                        </div>
                                        <div className="col-lg col-md-4 col-sm-6 mt-3 mt-lg-0">
                                            <button className="companies_monitors_button w-100">Social Media</button>
                                        </div>
                                        <div className="col-lg col-md-4 col-sm-6 mt-3 mt-lg-0">
                                            <button className="companies_monitors_button w-100">SDG Alignment</button>
                                        </div>
                                        <div className="col-lg col-md-4 col-sm-6 mt-3 mt-lg-0">
                                            <button className="companies_monitors_button w-100">News</button>
                                        </div>
                                        <div className="col-lg col-md-4 col-sm-6 mt-3 mt-lg-0">
                                            <button className="companies_monitors_button w-100">Jobs</button>
                                        </div>
                                        <div className="col-lg col-md-4 col-sm-6 mt-3 mt-lg-0">
                                            <button className="companies_monitors_button w-100">Regulations</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="row mt-4">
                            <div className="col-md-5">
                                <img src="img/reginos-mape.png" alt="img" />
                            </div>
                            <div className="col-md-3 col-12 col-sm-6">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="p-2">
                                            <h5 className="text-black fw-semibold mb-2">Company A</h5>
                                            <p>Present at: 14 locations in India</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <div className="regions_company_select">
                                            <div className="col-12">
                                                <select
                                                    className="form-select border fw-semibold text-black border bg-info bg-opacity-10 rounded">
                                                    <option >ESG Scores</option>
                                                    <option value="1">Company B</option>
                                                    <option value="2">Company C</option>
                                                </select>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <select
                                                    className="form-select border fw-semibold text-black border bg-info bg-opacity-10 rounded">
                                                    <option >Patents</option>
                                                    <option value="1">Company B</option>
                                                    <option value="2">Company C</option>
                                                </select>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <select
                                                    className="form-select border fw-semibold text-black border bg-info bg-opacity-10 rounded">
                                                    <option >Deals</option>
                                                    <option value="1">Company B</option>
                                                    <option value="2">Company C</option>
                                                </select>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <select
                                                    className="form-select border fw-semibold text-black border bg-info bg-opacity-10 rounded">
                                                    <option >Social Media</option>
                                                    <option value="1">Company B</option>
                                                    <option value="2">Company C</option>
                                                </select>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <select
                                                    className="form-select border fw-semibold text-black border bg-info bg-opacity-10 rounded">
                                                    <option >SDG Alignment</option>
                                                    <option value="1">Company B</option>
                                                    <option value="2">Company C</option>
                                                </select>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <select
                                                    className="form-select border fw-semibold text-black border bg-info bg-opacity-10 rounded">
                                                    <option >Jobs</option>
                                                    <option value="1">Company B</option>
                                                    <option value="2">Company C</option>
                                                </select>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-12 col-sm-6 mt-4 mt-lg-0">
                                <TopEsgStories />
                            </div>
                        </div>
                    </section>
                </div>
                    </div>
                </div>
            </Layout>

        </>
    )
}

export default Region;
