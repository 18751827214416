import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./App.css";

import { Provider } from 'react-redux';
import store from './store';
import {AppRoutes}  from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Provider store={store}>
    <Router >
    <ToastContainer theme='colored' position='top-center'/>
      <AppRoutes/>
    </Router>
  </Provider>
  );
}

export default App;