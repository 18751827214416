
import React from "react";
import LandingLayout from "../layouts/landing/LandingLayout";
import ContactUsForm from "../component/Forms/ContactUsForm";
import { Link } from "react-router-dom";
import { CheckCircleIcon } from "../icons";
function ThankYou() {
   return (
      <>
         <LandingLayout>
         <div className={"container-fluid px-4 py-lg-7 py-5"}>
                <div className="row justify-content-center">
                    <div className="col-lg-12 text-center text-info">
                        <CheckCircleIcon />
                    </div>
                    <div className="col-12 text-center mt-4">
                        <h1 className="text_demi mb-3">
                            Thank you!
                        </h1>
                        <h5>
                            Your submission is received and we will contact you soon.
                        </h5>
                        <a className="btn btn-sm get_proposal_btn mt-3" href={'/dashboard'} title='Go to database'>Go to dashboard</a> {/* Do not use Link Tag here */}
                    </div>
                </div>

                
            </div>
         </LandingLayout>
      </>
   )
}

export default ThankYou;
