import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
    company: { data: {}, loading: false },
    companies: { data: [], loading: false },
    companiesDropdown: { data: [], loading: false },
    countriesDropdown: { data: [], loading: false },
    continentDropdown: { data: [], loading: false },

};

const companySlice = createSlice({
    name: "company",
    initialState,
    reducers: {
        setCompanyDetails: (state, action: PayloadAction<any>) => {
            state.company = action.payload;
        },
        setCompanies: (state, action: PayloadAction<any>) => {
            state.companies = action.payload;
        },
        setCompaniesDropdown: (state, action: PayloadAction<any>) => {
            state.companiesDropdown = action.payload;
        },
        setCountriesDropdown: (state, action: PayloadAction<any>) => {
            state.countriesDropdown = action.payload;
        },
        setContinentDropdown: (state, action: PayloadAction<any>) => {
            state.continentDropdown = action.payload;
        },
        setLoading: (state, action: PayloadAction<{ key: string; loading: boolean }>) => {
            state[action.payload.key].loading = action.payload.loading;
        }

    },
});

export const { setCompanyDetails, setCompanies, setCompaniesDropdown, setCountriesDropdown,setContinentDropdown,setLoading } = companySlice.actions;
export default companySlice.reducer;


