
 import React from "react";
 import DynamicMeta from "../../component/DynamicMeta";
 import Layout from "../../layouts/dashboard/Layout";
 
function ThematicReport(){
    return (
        <>
           <DynamicMeta />
            <Layout >
                <div className="hk-pg-wrapper pb-10">
                    <div className="container-fluid px-4">
                        <div className="hk-pg-body pt-2">
                            <div className="">
                                <h1>Thematic Report</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ThematicReport;
 