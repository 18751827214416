import React from "react";
import UserProfile from "./Header/UserProfile";
import {  useSelector } from "react-redux";
import { setLayoutStyle } from "../../store/commonSlice";
import phoneHelpImage from "../../assets/img/phone-help.png"
import { Link, useLocation } from "react-router-dom";
import { GlobalSearch } from "../../component/SearchableDropdown";
import { useAppDispatch } from "../../hooks/useAppDispatch";

function Header() {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { layoutStyle } = useSelector(({ common }) => common); // Ensure type inference for useSelector
    const path= location.pathname.split("/")[1];

    const toggleLayoutStyle = () => {
        if (layoutStyle === 'default') {
            dispatch(setLayoutStyle('collapsed'));
        } else {
            dispatch(setLayoutStyle('default'));
        }
    };
   
    return (
        <>
            <div>
                <nav className="hk-navbar navbar navbar-expand-xl border-0 fixed-top py-3">
                    <div className="container-fluid px-4 position-relative">
                        <div className="nav-start-wrap">
                            <div>
                                <button
                                    className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover navbar-toggle d-xl-none"><span
                                        className="icon"><span className="feather-icon"><svg path="http://www.w3.org/2000/svg"
                                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                            strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                            className="feather feather-align-left" onClick={toggleLayoutStyle}>
                                            <line x1="17" y1="10" x2="3" y2="10"></line>
                                            <line x1="21" y1="6" x2="3" y2="6"></line>
                                            <line x1="21" y1="14" x2="3" y2="14"></line>
                                            <line x1="17" y1="18" x2="3" y2="18"></line>
                                        </svg></span></span>
                                </button>
                            </div>

                            { 
                            path !== 'dashboard' && <>
                            <div>
                                
                                    <div className="input-group d-flex"><span className="search_header_2">
                                        <GlobalSearch />
                                    </span></div>
                                
                            </div>
                            </>
                            }
                        </div>
                        <div className="nav-end-wrap">
                            <ul className=" navbar-nav flex-row">
                                <li className="nav-item ">
                                    <Link className="btn p-2 btn btn-outline-2 ms-3 p-1" title="Need Help" to={"#"}>
                                        <img src={phoneHelpImage} width="24" height="28" alt="Need Help" />
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <div className="dropdown dropdown-notifications"><Link to={"#"}
                                        className="btn btn-outline-2 ms-3 p-2" data-bs-toggle="dropdown"
                                        data-dropdown-animation="" role="button" aria-haspopup="true"
                                        aria-expanded="false"><span className="icon"><span className="position-relative"><svg
                                            stroke="currentColor" fill="currentColor" strokeWidth="0"
                                            viewBox="0 0 16 16" height="22" width="22"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <title>Notifications</title>
                                            <path
                                                d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z">
                                            </path>
                                        </svg></span></span></Link>
                                        <div className="dropdown-menu dropdown-menu-end p-0">
                                            <h6 className="dropdown-header px-4 fs-6">Notifications</h6>
                                            <div data-simplebar="init" className="dropdown-body  p-2">
                                                <div className="simplebar-wrapper" style={{ margin: "-8px" }}>
                                                    <div className="simplebar-height-auto-observer-wrapper">
                                                        <div className="simplebar-height-auto-observer"></div>
                                                    </div>
                                                    <div className="simplebar-mask">
                                                        <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
                                                            <div className="simplebar-content-wrapper"
                                                                style={{ height: "auto", overflow: "hidden" }}>
                                                                <div className="simplebar-content" style={{ padding: "8px" }}>
                                                                    <div className="row">
                                                                        <div className="404_sec my-lg-7 my-5">
                                                                            <div className="container">
                                                                                <div
                                                                                    className="row justify-content-center align-items-center">
                                                                                    <div
                                                                                        className="col-lg-8 col-sm-8 text-center">
                                                                                        <div className="text-center"><svg
                                                                                            stroke="currentColor"
                                                                                            fill="currentColor"
                                                                                            strokeWidth="0"
                                                                                            viewBox="0 0 1024 1024"
                                                                                            className="text-black-50"
                                                                                            height="70" width="70"
                                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                                            <path
                                                                                                d="M688 312v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm144 452H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h272c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm445.7 51.5l-93.3-93.3C814.7 780.7 828 743.9 828 704c0-97.2-78.8-176-176-176s-176 78.8-176 176 78.8 176 176 176c35.8 0 69-10.7 96.8-29l94.7 94.7c1.6 1.6 3.6 2.3 5.6 2.3s4.1-.8 5.6-2.3l31-31a7.9 7.9 0 0 0 0-11.2zM652 816c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z">
                                                                                            </path>
                                                                                        </svg></div>
                                                                                        <h3
                                                                                            className="mt-2 fw-semibold text-secondary">
                                                                                            Oops!</h3>
                                                                                        <p className="mb-4 text-secondary">No
                                                                                            notifications found. Check back
                                                                                            later for updates.</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="simplebar-placeholder" style={{ width: "0px", height: "0px" }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <UserProfile />
                            </ul>

                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default Header;