
import React from "react";
import esg_risk_rating from "../assets/img/esg-risk-rating.png"
import { Link } from "react-router-dom";
import { formatDate } from "../utils/constant";
import Charts from "./Charts/Charts";

export function RiskRating({ lastUpdate ,esgScore ,scopeChart=null }) {
    const riskRating = [
        {
            status: "Negligible",
            min: 0,
            max: 10
        },
        {
            status: "Low",
            min: 10,
            max: 20
        },
        {
            status: "Medium",
            min: 20,
            max: 30
        },
        {
            status: "High",
            min: 30,
            max: 40
        },
        {
            status: "Severe",
            min: 40,
            max: 100
        },
    ];

    const pointFormatter = function (y) {
        return '<span style="color:{point.color}">\u25CF</span> <b>' + y + '%</b><br/>';
    }
    const seriesData = [

        {
            name: 'Environment',
            data: [5, 15, 20, 14, 11]
        },
        {
            name: 'Social',
            data: [7, 10, 14, 19, 19]
        },
        {
            name: 'Governance',
            data: [5, 20, 22, 19, 5]
        }
    ]
    const XAxis = ['Neglible', 'Low', 'Medium', 'High', 'Sever']
    const chardData = scopeChart?.chart && JSON.parse(scopeChart?.chart)
    return (
        <div className="row">
            <div className="col-lg-6 ps-lg-0 pe-lg-0">
                <div className="companies_sec_border_1 p-3 bg-white h-100">
                    <h6 className="fw-semibold text-black font-s-16">ESG Risk Rating</h6>
                    <h2 className="fw-semibold text-black">{esgScore} <span
                        className="font-s-16">/100</span></h2>
                    <ul className="d-flex text-center esg_risk_rating">
                        {
                            riskRating.map((rating, index) => {
                                return (
                                    <li className="">

                                        <div className={esgScore >= rating.min && esgScore <=rating.max ? 'bg_alart_3':'bg_alart_1'}>{rating?.status}</div>
                                        <span>{rating?.min}-{rating?.max}</span>
                                    </li>
                                )
                            })
                        }

                    </ul>
                    <p className="mt-3">Calculated using our <Link to={"#"}
                        className="text-decoration-underline">ESG Risk Rating
                        Methodology</Link></p>
                    <p className="mt-1">Last Updated: {formatDate(lastUpdate) || "--"}</p>
                    <p className="mt-1"><Link to={"#"} className="text-decoration-underline">View
                        Company’s Business Model</Link></p>
                </div>
            </div>
            <div className="col-lg-6 ps-lg-0">
                <div className="companies_sec_border_2 p-3 bg-white h-100">
                    <h6 className="fw-semibold text-black font-s-16">ESG Risk Rating
                        Distribution</h6>
                    <img src={esg_risk_rating} className="img-fluid" alt="logo" />
                    {/* <BarChart seriesData={seriesData} XAxis={XAxis} pointFormatter={pointFormatter} height={'200px'} /> */}
                    {/* <Charts
                        type={'column'}
                        XAxis={[2020,2021,2023]}
                        showLegend={false}
                        height={"200px"}
                        seriesData={scopeChart}
                        pointFormatter={null}
                        
                    /> */}
                    {/* <HighchartsReact highcharts={Highcharts} options={chardData} /> */}
                </div>
            </div>
        </div>
    )
}
