import React, { useEffect, useState } from "react";
import DynamicMeta from "../../component/DynamicMeta";
import Layout from "../../layouts/dashboard/Layout";
import FilterBy from "../../component/FilterBy";
import { useParams } from "react-router-dom";

import PatentRow from "../../component/Rows/Patent";
import DealsRow from "../../component/Rows/Deals";
import JobRow from "../../component/Rows/Jobs";
import Regulations from "../../component/Rows/Regulations";
import NewsRow from "../../component/Rows/News";
import DatasetOverview from "./DatasetOverview";
function Dataset() {
  const { page } = useParams();
  const defaultComponentsOrder = [
    "CompanyFilter",
    "SectorFilter",
    "SubSectorFilter",
    "FromYearFilter",
    "ToYearFilter",
  ];
  const [componentsOrder, setComponentsOrder] = useState(
    defaultComponentsOrder
  );
  const [filterBy, setFilterBy] = useState("Company");

  useEffect(() => {
    switch (page) {
      case "patent":
        setComponentsOrder(["KeywordFilter", "CompanyFilter"]);
        setFilterBy("Patent");
        break;
      case "deals":
        setComponentsOrder([
          "CompanyFilter",
          "SectorFilter",
          "SubSectorFilter",
        ]);
        setFilterBy("Deals");
        break;
      case "jobs":
        setComponentsOrder([
          "CompanyFilter",
          "SectorFilter",
          "SubSectorFilter",
          "FromMonthYearFilter",
          "ToMonthYearFilter",
        ]);
        setFilterBy("Jobs");
        break;
      case "regulations":
        setComponentsOrder(["KeywordFilter", "RegionFilter", "CountryFilter"]);
        setFilterBy("Regulations");
        break;
      case "news":
        setComponentsOrder([
          "CompanyFilter",
          "SectorFilter",
          "FromYearFilter",
          "ToYearFilter",
        ]);
        setFilterBy("News");
        break;
      default:
        setComponentsOrder(defaultComponentsOrder);
        setFilterBy("Company");
        break;
    }
  }, [page]);

  return (
    <>
      <DynamicMeta />
      <Layout>
        <div className="hk-pg-wrapper pb-10">
          <div className="container-fluid px-4">
            <div className="hk-pg-body pt-2 pb-14">
              <>
                {page === "regulations" ? (
                  <Regulations />
                ) : (
                  <>
                    <FilterBy
                      filterBy={filterBy}
                      componentsOrder={componentsOrder}
                    />
                    <div className="row">
                      <div className="col-12">
                        {page === undefined && <DatasetOverview />}
                        {page === "patent" && <PatentRow />}
                        {page === "deals" && <DealsRow />}
                        {page === "jobs" && <JobRow />}
                        {page === "news" && <NewsRow />}
                      </div>
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Dataset;
