// DynamicMeta.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

const DynamicMeta = () => {

  const { pageTitle, description,index ,ogImage} = useSelector(({ common }) => common);
  const indexContent = index === false || index === '' || index === undefined || index === null ? 'noindex' : 'index' ;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <meta name="robots" content={indexContent} />
      <meta name="googlebot" content={indexContent} />
      {ogImage ? 
      <meta property="og:url" content={`"https://www.grandviewresearch.com/horizon/reports/${ogImage}"`} />
      :
      <meta property="og:url" content="https://www.grandviewresearch.com/industry/automotive-and-aerospace-interior-materials" />
      }
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Advanced Interior Materials Market Consulting and Research Reports - Grand View Research" />
      <meta property="og:description" content="Advanced Interior Materials Market Consulting and Research Reports - Grand View Research" />
      <meta property="og:image" content="https://www.grandviewresearch.com/static/img/logo.png" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@GrandViewInc" />
      <meta name="twitter:title" content="Advanced Interior Materials Market Consulting and Research Reports - Grand View Research" />
      <meta name="twitter:description" content="Advanced Interior Materials Market Consulting and Research Reports - Grand View Research" />
      <meta name="twitter:image:alt" content="Advanced Interior Materials Market Consulting and Research Reports - Grand View Research" />
      <meta name="twitter:image" content="https://www.grandviewresearch.com/static/img/logo.png" />
      <link rel="canonical" href="https://www.grandviewresearch.com/industry/automotive-and-aerospace-interior-materials" />
      
    </Helmet>
  );
};

export default DynamicMeta;
