import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
    sectorDetail: { data: {}, loading: false },
    sectors: { data: [], loading: false },
    sectorsDropdown: { data: [], loading: false },
    subSectorsDropdown: { data: [], loading: false },
    esgScore: { data: { all_score: 0, e_score: 0, g_score: 0, is_publish: 0, research_id: 0, s_score: 0 }, loading: false },
};

const sectorsSlice = createSlice({
    name: "sector",
    initialState,
    reducers: {
        setSectorDetails: (state, action: PayloadAction<any>) => {
            state.sectorDetail = action.payload;
        },
        setSectors: (state, action: PayloadAction<any>) => {
            state.sectors = action.payload;
        },
        setSectorsDropdown: (state, action: PayloadAction<any>) => {
            state.sectorsDropdown = action.payload;
        },
        setSubSectorsDropdown: (state, action: PayloadAction<any>) => {
            state.subSectorsDropdown = action.payload;
        },
        setEsgScore: (state, action: PayloadAction<any>) => {
            state.esgScore = action.payload;
        },
        setLoading: (state, action: PayloadAction<{ key: string; loading: boolean }>) => {
            state[action.payload.key].loading = action.payload.loading;
        }
    },
});

export const { setSectorDetails, setSectors, setLoading, setSectorsDropdown, setSubSectorsDropdown, setEsgScore } = sectorsSlice.actions;
export default sectorsSlice.reducer;
