import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./authSlice";
import commonSlice from "./commonSlice";
import companySlice from "./companySlice";
import sectorsSlice from "./sectorsSlice";
import datasetSlice from "./datasetSlice";

const store = configureStore({
  reducer: {
    common: commonSlice,
    auth: authSlice,
    company: companySlice,
    sector: sectorsSlice,
    dataset: datasetSlice,
  },
  // Middleware can be configured if needed
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
