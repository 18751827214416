// api-client.js

import axios from 'axios';
import Cookies from 'js-cookie';
import { unAuthenticatedRoute } from './constant';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    withXSRFToken: true,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
       // 'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN')
       'X-Requested-With': 'XMLHttpRequest',
    },
});
// Add a request interceptor
apiClient.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (unAuthenticatedRoute.includes(config?.url)) {
        return config;
    }
    return config;

    // return Promise.reject(new Error('Unauthenticated'));
    
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
apiClient.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});


export async function csrfGeneratee() {
    if (Cookies.get('XSRF-TOKEN') === undefined) {
      return new Promise((resolve, reject) => {
        apiClient.get('/sanctum/csrf-cookie').then(response => {
          console.log("Token Genarated successfully")
          resolve(response)
        }).catch((error) => {
          console.error("Token not generated - ", error)
          reject({ error: error })
        })
      });
    }
  }
export default apiClient;