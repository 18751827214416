import React from 'react';
// import images from "../../images";
import { Link } from 'react-router-dom';
import LandingLayout from '../../layouts/landing/LandingLayout';

export default function ErrorLanding() {
    return (
        <LandingLayout >
            <div className="row">
                <div className="404_sec my-lg-7 my-5">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-4 col-sm-6">
                                <div className="text-center">
                                    {/* <img src={images.error_page} width="355" height="307" /> */}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 text-center text-sm-start">
                                <h1 className="fw-semibold text_purple mb-3">404</h1>
                                <p className="mb-4 text-secondary">Sorry, The reuested  page cannot be found. Try finding with another name.</p>
                                <Link to="/" className="btn btn_overview_blue" title='Return To Home'>Return To Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LandingLayout>
    )

}