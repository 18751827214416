import React, { useState, ChangeEvent, FormEvent } from "react";
import { useSelector } from "react-redux";
import { forgotPassword } from "../../services/authService"; // Assuming you have defined the ForgotPasswordDetail type
import { validateEmail } from "../../utils/constant";
import { ButtonSpinner } from "../../component/Button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordData } from "../../types/authTypes";
import { toast } from "react-toastify";


function ForgotPasswordForm() {
    const dispatch = useAppDispatch();
    const { loader } = useSelector(({common}) => common); // Replace 'any' with the correct type
    const navigate = useNavigate();
    

    const defaultValues: ForgotPasswordData = { email: "" };
    const [detail, setDetails] = useState<ForgotPasswordData>(defaultValues);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!detail.email) {
            toast.error("What's your email?");
            return;
        }

        if (!validateEmail(detail.email)) {
            toast.error("Please enter a valid email address");
            return;
        }

        dispatch(forgotPassword(detail));
    };

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setDetails((oldDetail) => ({
            ...oldDetail,
            [name]: value,
        }));
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="form-label-group mb-4">
                    <h4 className="my-0">Forgot Password?</h4>
                    <a href={"#"} onClick={() => navigate("/sign-in#")} title="Sign In" className="text-decoration-underline">
                        <u>Back to Sign in</u>
                    </a>
                </div>
                <div className="row gx-3">
                    
                </div>
                <div className="form-floating mb-4">
                    <input
                        className="form-control"
                        placeholder="Enter your email address"
                        type="email"
                        id="floatingEmail"
                        value={detail.email}
                        name="email"
                        onChange={handleOnChange}
                    />
                    <label htmlFor="floatingEmail">Email Address</label>
                </div>
                <div className="mb-4">
                    <button
                        className="as_info_btn btn-uppercase btn-block"
                        title="Send Reset Password Link"
                        disabled={loader}
                    >
                        {loader && <ButtonSpinner />} Send Reset Password Link
                    </button>
                </div>
            </form>
        </>
    );
};

export default ForgotPasswordForm;
