import { setUserDetails } from "../store/authSlice";
import { fetcherGet, fetcherPost, fetcherPut } from "../utils/fetcher";
import { setAccessShow, setLoader } from "../store/commonSlice";
import { toast } from "react-toastify";
import { AppDispatch } from "../store";
import { ChangePasswordData, ForgotPasswordData, SendAccessCodeLinkData, SignInData, SignUpData, UpdateAccountData, UpdatePasswordData, VerifyOtpData } from "../types/authTypes";
import { CommonResponseState } from "../types/commonTypes";
export function signUp(data: SignUpData) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        return new Promise((resolve, reject) => {
            fetcherPost(`signup`, data)
                .then((response: CommonResponseState) => {
                    if (response.status === 201) {
                        dispatch(setUserDetails(response.user));
                        dispatch(setAccessShow(true))
                        toast.success(response.message)
                    }
                    resolve(response);
                })
                .catch((error) => {
                    toast.error(error?.response?.data.message || error?.message)
                    reject(error)
                }).finally(() => {
                    dispatch(setLoader(false));
                });
        });
    };
}

export function signIn(data: SignInData) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        return new Promise((resolve, reject) => {
            fetcherPost(`sign-in`, data)
                .then((response: CommonResponseState) => {
                    if (response?.status === 200) {
                        dispatch(setUserDetails(response?.user));
                        toast.success(response?.message)
                        resolve(response)
                    }
                   
                })
                .catch((error) => {
                    toast.error(error?.response?.data.message || error?.response?.message)
                    if(error.response?.status === 403){
                        dispatch(setAccessShow(true));
                    }
                    reject(error)
                }).finally(() => {
                    dispatch(setLoader(false));
                });
        })
    };

}

export function getUserDetails() {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        return new Promise((resolve, reject) => {
            fetcherGet(`users/get-current-user`)
                .then((response: CommonResponseState) => {
                    dispatch(setUserDetails(response?.data));
                    resolve(response)
                })
                .catch((error) => {
                    if (error?.response?.status === 400 || error?.response?.status === 422) {
                        toast.error(error?.response?.data.message || error?.response?.message)
                    } else if (error?.response?.status === 401) {
                        // toast.error("Unauthenticated")
                    }
                    reject(error)
                }).finally(() => {
                    dispatch(setLoader(false));
                });
        })
    };
}

export function signOut() {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        return new Promise((resolve, reject) => {
            fetcherPost(`signout`, [])
                .then((response: any) => {
                    if (response?.message) {
                        dispatch(setUserDetails(null))
                    }
                    resolve(response);
                })
                .catch((error) => {
                    if (error?.response?.status === 400 || error?.response?.status === 422) {
                        toast.error(error?.response?.data.message || error?.response?.message)
                    } else {
                        toast.error(error?.message)
                    }
                    reject(error)
                }).finally(() => {
                    dispatch(setLoader(false));
                });
        });
    };
}

export function changePassword(data: ChangePasswordData) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        return new Promise((resolve, reject) => {
            fetcherPost(`change-password`, data)
                .then((response: CommonResponseState) => {
                    if (response.status === 200) {
                        toast.success(response?.message)
                    }
                    resolve(response);
                })
                .catch((error) => {
                    reject(error)
                }).finally(() => {
                    dispatch(setLoader(false));

                });
        });
    };
}


export function forgotPassword(data: ForgotPasswordData) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        await fetcherPost(`forgot-password`, data)
            .then((response: CommonResponseState) => {
                if (response?.status) {
                    toast.success(response.message)
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error?.message)
            }).finally(() => {
                dispatch(setLoader(false));
            });
    };
}

export function updatePassword(data: UpdatePasswordData) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        return new Promise((resolve, reject) => {
            fetcherPost(`update-password`, data)
                .then((response: CommonResponseState) => {
                    if (response.status === 200) {
                        toast.success(response.message)
                    } else {
                        toast.success(response.error)
                    }
                    resolve(response)
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message || error?.message)
                    reject(error)
                }).finally(() => {
                    dispatch(setLoader(false));
                })
        });
    };
}

export function verifyAccount(token: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        await fetcherGet(`verification/${token}`)
            .then((response: CommonResponseState) => {
                if (response?.status === 200) {
                    dispatch(setUserDetails(response?.data));
                    toast.success(response.message)
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error?.message)
            }).finally(() => {
                dispatch(setLoader(false));
            })
    };
}

export function updateAccount(data: UpdateAccountData) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        return new Promise((resolve, reject) => {
            fetcherPut(`update-user`, data)
            .then((response: CommonResponseState) => {
                if (response?.status === 200) {
                    toast.success(response.message)
                }
                resolve(response);
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message || error?.message)
            reject(error)
            }).finally(() => {
                dispatch(setLoader(false));

             });
        });
    };
}

export function sendAccessCodeLink(data: SendAccessCodeLinkData) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true));
        return new Promise((resolve, reject) => {
            fetcherPost(`resend-otp`, data)
                .then((response: CommonResponseState) => {
                    if (response?.status === 200) {
                        toast.success(response.message);
                        resolve(response);
                    } else {
                        const error = new Error('Unexpected response status');
                        toast.error('Unexpected response status')
                        reject(error);
                    }
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message || error?.message);
                    reject(error);
                })
                .finally(() => {
                    dispatch(setLoader(false));
                })
        });
    };
}


export function verifyOtp(data: VerifyOtpData) {
    return async (dispatch: AppDispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(setLoader(true));
            fetcherPost(`verify-otp`, data)
                .then((response: CommonResponseState) => {
                    if (response.status === 200) {
                        toast.success(response.message)
                        dispatch(setAccessShow(false))
                    }
                    resolve(response)
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message || error?.message)
                    reject(error)

                }).finally(() => {
                    dispatch(setLoader(false));
                })
        });
    };
}