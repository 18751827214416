import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "./store";
import { CommonState, filterState } from "../types/commonTypes";
import { resolve } from "path";
import { theme } from "highcharts";



const initialState: CommonState = {
  loader: false,
  pageTitle: 'NeoImpact',
  description: '',
  index: '',
  ogImage: '',
  layoutStyle: "default",
  layoutHover: "default",
  filter: {},
  accessShow: false,
  refresh: 0
};


export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setIndex: (state, action) => {
      state.index = action.payload;
    },
    setOgImage: (state, action) => {
      state.ogImage = action.payload;
    },
    setLayoutStyle: (state, action) => {
      state.layoutStyle = action.payload;
    },
    setLayoutHover: (state, action) => {
      state.layoutHover = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },

    setAccessShow: (state, action) => {
      state.accessShow = action.payload;
    },

    setRefresh: (state) => {
      state.refresh += 1;
    },
  },
});

export default commonSlice.reducer;
export const { setLoader, setPageTitle, setLayoutStyle, setLayoutHover, setFilter, setAccessShow, setRefresh } = commonSlice.actions;

export function updateFilter(filter: filterState) {
  return async (dispatch: AppDispatch) => {
    return new Promise((resolve, reject) => {
       dispatch(setFilter(filter))
       resolve(true)    
    })
  };
}