import React, { useEffect } from "react";
import  feature_img from '../../assets/img/feature_img.jpg'
import LandingLayout from "../../layouts/landing/LandingLayout";
import DynamicMeta from "../../component/DynamicMeta";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../store/commonSlice";
import { Link } from "react-router-dom";

const Home = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(setPageTitle("NeoImpact - HOME"))
    },[dispatch])

    return (
        <>
            <DynamicMeta />
            <LandingLayout >
                <div className="home2_banner">
                    <div className="container position-relative z-index">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-9">
                                <h1 className="mb-0 text-center text-white text_demi text-uppercase">Best-in-class ESG Intelligence SaaS Platform</h1>
                                <p className="mb-md-5 mb-4 text-center text-white">NeoImpact’s ESG platform runs on a comprehensive set of ESG fundamental and alternative data sets designed to provide robust and real-time ESG insights and analytics.</p>
                                <form className="position-relative">
                                    <input type="text" className="form-control" placeholder="Find ESG Disclosure, Regulations, Patents, Investments and many more..." />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#535353" className="bi bi-search search_bar_icn" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                    <button className="btn" type="submit">Search </button>
                                </form>``
                                <ul className="banner_tag text-center mt-4">
                                    <li>Popular Industries:</li>
                                    <li><Link title="Financial Services" to={""}>Financial Services</Link></li>
                                    <li><Link title="Specialty &amp; Fine Chemicals" to={""}>Specialty &amp; Fine Chemicals</Link></li>
                                    <li><Link title="Plastics Polymers &amp; Resins" to={""}>Plastics Polymers &amp; Resins</Link></li>
                                    <li><Link title="MedTech" to={""}>MedTech</Link></li><li><Link title="Healthcare IT" to={""}>Healthcare IT</Link></li>
                                    <li><Link title="Animal Health" to={""}>Animal Health</Link></li>
                                    <li><Link title="Biotechnology" to={""}>Biotechnology</Link></li>
                                    <li><Link title="Clinical Diagnostics" to={""}>Clinical Diagnostics</Link></li>
                                    <li><Link title="Semiconductor &amp; Electronics" to={""}>Semiconductor &amp; Electronics</Link></li>
                                    <li><Link title="Telecom Media &amp; Technology" to={""}>Telecom Media &amp; Technology</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-5 py-lg-7 insights_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <h3 className="text_purple text_demi">We serve the best work</h3>
                                <nav>
                                    <div className="insight_tab" id="nav-tab" role="tablist">
                                        <button className="active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-briefcase" viewBox="0 0 16 16">
                                                <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                                            </svg>
                                            ESG Intelligence
                                        </button>
                                        <button className="" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z" />
                                            </svg>
                                            Data Centralization
                                        </button>
                                        <button className="" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-cash-coin" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z" />
                                                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                                                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                                                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                                            </svg>
                                            Impact Benchmarking</button>
                                        <button className="" id="nav-4-tab" data-bs-toggle="tab" data-bs-target="#nav-4" type="button" role="tab" aria-controls="nav-4" aria-selected="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-display" viewBox="0 0 16 16">
                                                <path d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4c0 .667.083 1.167.25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75c.167-.333.25-.833.25-1.5H2s-2 0-2-2V4zm1.398-.855a.758.758 0 0 0-.254.302A1.46 1.46 0 0 0 1 4.01V10c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.758.758 0 0 0 .254-.302 1.464 1.464 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.757.757 0 0 0-.302-.254A1.46 1.46 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145z" />
                                            </svg>
                                            Thematic Research
                                        </button>
                                        <button className="" id="nav-5-tab" data-bs-toggle="tab" data-bs-target="#nav-5" type="button" role="tab" aria-controls="nav-5" aria-selected="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-rocket-takeoff" viewBox="0 0 16 16">
                                                <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z" />
                                                <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z" />
                                                <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z" />
                                            </svg>
                                            Regulatory Solutions
                                        </button>
                                       
                                    </div>
                                </nav>
                            </div>
                            <div className="col-lg-8">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex={0}>
                                        <div className="rounded shadow-sm bg-white radius mt-lg-0 mt-4">
                                            <img src={feature_img} className="rounded rounded-bottom-0" alt="img" />
                                            <div className="p-4">
                                                <div className="row">
                                                    <div className="col-lg-4 border-end border-2">
                                                        <h4 className="fw-bold">ESG for Private Market</h4>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <div className="ps-lg-3">
                                                            <p>NeoImpact offers you a comprehensive suite of technology with ESG expertise alongside ESG fundamental and alternative data analytics to direct effective decision making.</p>
                                                            <Link to={""}>
                                                                Read More
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="ms-1 bi bi-arrow-right" viewBox="0 0 16 16">
                                                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                                                                </svg>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex={0}>...</div>
                                    <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabIndex={0}>...</div>
                                    <div className="tab-pane fade" id="nav-4" role="tabpanel" aria-labelledby="nav-4-tab" tabIndex={0}>...</div>
                                    <div className="tab-pane fade" id="nav-5" role="tabpanel" aria-labelledby="nav-5-tab" tabIndex={0}>...</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="py-5 py-lg-7 case_study_sec">
                    <div className="container">
                        <h2 className="text_demi text_purple mb-0 mb-sm-3 text-center">Featured Case Studies</h2>
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="shadow-sm rounded bg-white">
                                           
                                            <div className="p-4">
                                                <span className="rounded bg-secondary bg-opacity-10 px-2 py-1 font-s-12">Self serve B2B SaaS</span>
                                                <ul className="mt-3">
                                                    <li className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                        </svg>
                                                        +1,745 free trial signups
                                                    </li>
                                                    <li className="mb-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                        </svg>
                                                        +232,493 organic sessions
                                                    </li>
                                                </ul>
                                                <Link to={""} className="fw-bold text_Bondi_Blue hover_underline">Read Case Study</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="shadow-sm rounded bg-white">
                                         
                                            <div className="p-4">
                                                <span className="rounded bg-secondary bg-opacity-10 px-2 py-1 font-s-12">Self serve B2B SaaS</span>
                                                <ul className="mt-3">
                                                    <li className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                        </svg>
                                                        +1,745 free trial signups
                                                    </li>
                                                    <li className="mb-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                        </svg>
                                                        +232,493 organic sessions
                                                    </li>
                                                </ul>
                                                <Link to={""} className="fw-bold text_Bondi_Blue hover_underline">Read Case Study</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="shadow-sm rounded bg-white">
                                           
                                            <div className="p-4">
                                                <span className="rounded rounded-bottom-0 bg-secondary bg-opacity-10 px-2 py-1 font-s-12">Self serve B2B SaaS</span>
                                                <ul className="mt-3">
                                                    <li className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                        </svg>
                                                        +1,745 free trial signups
                                                    </li>
                                                    <li className="mb-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                        </svg>
                                                        +232,493 organic sessions
                                                    </li>
                                                </ul>
                                                <Link to={""} className="fw-bold text_Bondi_Blue hover_underline">Read Case Study</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="shadow-sm rounded bg-white">
                                            
                                            <div className="p-4">
                                                <span className="rounded bg-secondary bg-opacity-10 px-2 py-1 font-s-12">Self serve B2B SaaS</span>
                                                <ul className="mt-3">
                                                    <li className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                        </svg>
                                                        +1,745 free trial signups
                                                    </li>
                                                    <li className="mb-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                        </svg>
                                                        +232,493 organic sessions
                                                    </li>
                                                </ul>
                                                <Link to={""} className="fw-bold text_Bondi_Blue">Read Case Study</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="shadow-sm rounded bg-white">
                                           
                                            <div className="p-4">
                                                <span className="rounded rounded-bottom-0 bg-secondary bg-opacity-10 px-2 py-1 font-s-12">Self serve B2B SaaS</span>
                                                <ul className="mt-3">
                                                    <li className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                        </svg>
                                                        +1,745 free trial signups
                                                    </li>
                                                    <li className="mb-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                                        </svg>
                                                        +232,493 organic sessions
                                                    </li>
                                                </ul>
                                                <Link to={""} className="fw-bold text_Bondi_Blue">Read Case Study</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mt-4">
                                        <div className="shadow-sm rounded bg-white">
                                            
                                            <div className="p-4">
                                                <span className="rounded bg-secondary bg-opacity-10 px-2 py-1 font-s-12">Self serve B2B SaaS</span>
                                                <ul className="mt-3">
                                                    <li className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                                        </svg>
                                                        +1,745 free trial signups
                                                    </li>
                                                    <li className="mb-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                                                            <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                                        </svg>
                                                        +232,493 organic sessions
                                                    </li>
                                                </ul>
                                                <Link to={""} className="fw-bold text_Bondi_Blue">Read Case Study</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-lg-7 py-5 hz_latest_report_sec bg-opacity-10">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h4 className="text_demi text_purple mb-0 recent_public_heading">Recently Activity</h4>
                            </div>
                            <div className="col-md-6 mt-4">
                                <div className="shadow shadow-sm rounded-4 bg-white p-4">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bar-chart-line" viewBox="0 0 16 16">
                                                <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z" />
                                            </svg>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <Link to={""} target="_blank"><h4 className="recent_public_media">Energy Conservation Program</h4></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-4">
                                <div className="shadow shadow-sm rounded-4 bg-white p-4">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bar-chart-line" viewBox="0 0 16 16">
                                                <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z" />
                                            </svg>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <Link to={""} target="_blank"><h4 className="recent_public_media">Air Quality Regulation</h4></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-4">
                                <div className="shadow shadow-sm rounded-4 bg-white p-4">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bar-chart-line" viewBox="0 0 16 16">
                                                <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z" />
                                            </svg>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <Link to={""} target="_blank"><h4 className="recent_public_media">Preliminary Risk Assessment</h4></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-4">
                                <div className="shadow shadow-sm rounded-4 bg-white p-4">
                                    <div className="d-flex align-items-center">
                                        <div className="flex-shrink-0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bar-chart-line" viewBox="0 0 16 16">
                                                <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z" />
                                            </svg>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <Link to={""} target="_blank"><h4 className="recent_public_media">Energy Regulation</h4></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-center mt-5">
                                <Link to={""} className="more_video_btn">View All</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-5 py-lg-7 hz_plan_sec bg-secondary bg-opacity-10">
                    <div className="container">
                        <h2 className="text_demi text_purple mb-0 mb-sm-3 text-center">Services Packages</h2>
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="row">
                                    <div className="col-lg-4 mt-4">
                                        <div className="shadow-sm rounded-4 bg-white position-relative overflow-hidden h-100">
                                            <div className="p-4">
                                                <div className="text-center">
                                                    <div className="fw-bold text_Bondi_Blue">Free</div>
                                                    <div className="plan_heading text_demi my-3">$0<small>Per Month</small></div>
                                                    <Link to={""} className="get_proposal_outline mb-2">Get a Proposal Now</Link>
                                                </div>
                                                <ul className="mt-4 hz_plan_check">
                                                    <li className="mb-2">
                                                        Free global market statistics
                                                    </li>
                                                    <li className="mb-2">
                                                        Download graphs
                                                    </li>
                                                    <li className="mb-2">
                                                        Access to newsletters & use-cases
                                                    </li>
                                                    <li className="mb-2">
                                                        Free industry reports
                                                    </li>
                                                    <li className="mb-2">
                                                        License to use data citing GVR
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-4">
                                        <div className="shadow-sm rounded-4 bg-white position-relative overflow-hidden h-100">
                                            <div className="p-4">
                                                <div className="text-center">
                                                    <div className="fw-bold">Individual</div>
                                                    <div className="plan_heading text_demi my-3">$499<small>Per Month</small></div>
                                                    <Link to={""} className="get_proposal_outline mb-2">Get a Proposal Now</Link>
                                                </div>
                                                <ul className="mt-4 hz_plan_check ">
                                                    <li className="mb-2">
                                                        Not applicable to corporates
                                                    </li>
                                                    <li className="mb-2">
                                                        For start-ups/students/researchers at individual capacity
                                                    </li>
                                                    <li className="mb-2">
                                                        Access to over a million market statistics
                                                    </li>
                                                    <li className="mb-2">
                                                        Download in XLS, PNG
                                                    </li>
                                                    <li className="mb-2">
                                                        Access to over 8,500 market reports in 45+ industries
                                                    </li>
                                                    <li className="mb-2">
                                                        Free customization of 10%
                                                    </li>
                                                    <li className="mb-2">
                                                        Access to newsletters & use-cases
                                                    </li>
                                                    <li className="mb-2">
                                                        Free industry reports
                                                    </li>
                                                    <li className="mb-2">
                                                        License to use data citing GVR
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-4">
                                        <div className="shadow-sm rounded-4 bg-white position-relative overflow-hidden h-100">
                                            <div className="p-4">
                                                <span className="plan_badge">Preferred</span>
                                                <div className="text-center">
                                                    <div className="fw-bold">Corporate</div>
                                                    <div className="plan_heading text_demi my-3">$1999<small>Per Month</small></div>
                                                    <Link to={""} className="get_proposal_btn mb-2">Get a Proposal Now</Link>
                                                </div>
                                                <ul className="mt-4 hz_plan_check">
                                                    <li className="mb-2">
                                                        All benefits of individual account
                                                    </li>
                                                    <li className="mb-2">
                                                        Unlimited users
                                                    </li>
                                                    <li className="mb-2">
                                                        Access to 20% customziation
                                                    </li>
                                                    <li className="mb-2">
                                                        Free 10 hours of consulting services (analytics, ESG, supply chain etc.. (BROWSE SERVICES)
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 border-top mt-5">
                                        <div className="row">
                                            <div className="col-lg-4 mt-4">
                                                <div className="p-4">
                                                    <ul>
                                                        <li className="mb-2">
                                                            Select industries that you need to access and custom build a price packkage
                                                        </li>
                                                        <li className="mb-2">
                                                            Select customization packages (Starting at 20% going up to 35%)
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mt-4">
                                                <div className="p-4">
                                                    <ul>
                                                        <li className="mb-2">
                                                            Select industries that you need to access and custom build a price packkage
                                                        </li>
                                                        <li className="mb-2">
                                                            Select customization packages (Starting at 20% going up to 35%)
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mt-4">
                                                <div className="p-4 text-center ">
                                                    <div className="">
                                                        <div className="fw-bold">CUSTOM RESEARCH</div>
                                                    </div>
                                                    <div className="my-2">
                                                        For a fully customized pricing solution,
                                                    </div>
                                                    <Link to={""} className="get_proposal_btn mb-2 text-center">GET IN TOUCH</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="success_sec pt-5 pt-lg-7 pb-lg-7 pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="text_demi text_purple mb-5 text-center">Top Rated By <span className="sucess_rate_color">Customer &</span> 100% <br /> Success Rate</h2>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="row pb-5">
                                    <div className="col-lg-3 col-sm-6 mb-5 mb-lg-0 position-relative success_border">
                                        <div className="">
                                            <div className="text_demi sucess_heading">1200+</div>
                                            <h5 className="mb-0 fw-bold text_purple lh-base"> Reports Published <br /> Per Years </h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-5 mb-lg-0 position-relative success_border">
                                        <div className="">
                                            <div className="text_demi sucess_heading">1750+</div>
                                            <h5 className="mb-0 fw-bold text_purple lh-base"> Consulting Projects <br /> Till Date </h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-5 mb-lg-0 position-relative success_border">
                                        <div className="">
                                            <div className="text_demi sucess_heading">450+</div>
                                            <h5 className="mb-0 fw-bold text_purple lh-base"> Analysts And <br /> Consultants </h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-sm-6 mb-5 mb-lg-0 position-relative success_border last">
                                        <div className="">
                                            <div className="text_demi sucess_heading">17550+</div>
                                            <h5 className="mb-0 fw-bold text_purple lh-base"> Client Inquiries <br /> In 2020 </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="py-5 py-lg-7 video_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h6 className="text_Bondi_Blue fw-bold">ESG Reporting</h6>
                            </div>
                            <div className="col-lg-6">
                                <h3 className="text_demi text_purple mb-3 lh-base">Best-in-class deep tech ESG intelligence platform for private markets</h3>
                            </div>
                            
                            <div className="col-12">
                                <iframe width="100%" height="500" title="tgbNymZ7vqY"
                                    src="https://www.youtube.com/embed/OT3gsCbCKdI?si=TlUVUbdbCB1sKkLK">
                                </iframe>
                               
                            </div>
                           
                        </div>
                    </div>
                </div>
            </LandingLayout>

        </>
    )
}

export default Home;

