import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineFileSearch } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { setRefresh, updateFilter } from "../store/commonSlice";

export default function DataNotFound({ title = "test", description = "", showButton = false, buttonText = "", buttonRedirect = "", showRelatedBtn = false }) {
    const dispatch = useAppDispatch();
    const { filter } = useSelector(({ common }) => common);
    const { company } = useSelector(({ company }) => company);
    // const [showRelated,setShowRelated]= useState(false);
    // useEffect(() => {
    //     dispatch(updateFilter({ ...filter, company: { id: company?.data?._id, name: company?.data?.name }, showRelated: true }))
    //     dispatch(setRefresh())
    // },[showRelated])
    return (
            <div className="404_sec my-lg-7 my-5">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-12 col-sm-8 text-center">
                            <div className="text-center">
                                <AiOutlineFileSearch size={70} className="text-black-50" />
                            </div>
                            <h3 className="mt-2 fw-semibold text-secondary">{title}</h3>
                            <p className="mb-4 text-secondary">{description}</p>
                            {showButton && <Link to={buttonRedirect} className="btn btn-sm btn-primary" title={buttonText}>{buttonText}</Link>}
                            {showRelatedBtn && <Link to={''}
                                onClick={
                                    () => {                                        
                                        dispatch(updateFilter({ ...filter, company: { id: company?.data?._id, name: company?.data?.name }, showRelated: true }))
                                        dispatch(setRefresh())
                                    }
                                }
                                className="btn btn-sm btn-primary" title={'Show Related'}>Show Related</Link>}
                        </div>
                    </div>
                </div>
            </div>
    )

}