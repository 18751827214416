import { PayloadAction, createSlice } from "@reduxjs/toolkit";


const initialState = {
    patents: { data: [], loading: false },
    deals: { data: [], loading: false },
    jobs: { data: [], loading: false },
    news: { data: [], loading: false },
    regulations: { data: [], loading: false },
    jobsGraphDetails: { data: [], loading: false },
    dealsGraphDetails: { data: [], loading: false },
    patentGraphDetails: { data: [], loading: false },

};

const datasetSlice = createSlice({
    name: "dataset",
    initialState,
    reducers: {
        setPatents: (state, action: PayloadAction<any>) => {
            state.patents = action.payload;
        },
        setdDeals: (state, action: PayloadAction<any>) => {
            state.deals = action.payload;
        },
        setJobs: (state, action: PayloadAction<any>) => {
            state.jobs = action.payload;
        },
        setNews: (state, action: PayloadAction<any>) => {
            state.news = action.payload;
        },
        setRegulations: (state, action: PayloadAction<any>) => {
            state.regulations = action.payload;
        },
        setJobsGraphDetails: (state, action: PayloadAction<any>) => {
            state.jobsGraphDetails = action.payload;
        },
        setDealsGraphDetails: (state, action: PayloadAction<any>) => {
            state.dealsGraphDetails = action.payload;
        },
        setPatentGraphDetails: (state, action: PayloadAction<any>) => {
            state.patentGraphDetails = action.payload;
        },
        setLoading: (state, action: PayloadAction<{ key: string; loading: boolean }>) => {
            state[action.payload.key].loading = action.payload.loading;
        }
    },
});

export const { setPatents, setdDeals, setJobs, setNews, setRegulations, setJobsGraphDetails, setDealsGraphDetails, setPatentGraphDetails, setLoading } = datasetSlice.actions;
export default datasetSlice.reducer;

