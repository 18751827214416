import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BsCheckCircle } from 'react-icons/bs';
import logo from '../../assets/img/logo.png'
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { setAccessShow } from '../../store/commonSlice';
import AccessForm from '../Forms/AccessForm';


export default function AccessModel() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {accessShow  } = useSelector(({ common }) => common)
    return <Modal show={accessShow} onHide={() => dispatch(setAccessShow(false))} backdrop="static" keyboard={false} dialogClassName='mx-auto modal-dialog-centered modal-lg'>
        <button id="SUFCloseBtn" onClick={() =>dispatch(setAccessShow(false)) } type="button" className="btn-close btn-close-corner-normal" aria-label="Close" title='Close'></button>
        <div className="row m-lg-0">
            <div className="col-lg-7 px-lg-0">
                <div className="p-5">
                    <AccessForm />
                </div>
            </div>
            <div className="col-lg-5 px-lg-0 rounded-end bg_light">
                <div className="d-lg-block d-none">
                    <div className="p-5 ">
                        <div className="text-center mt-6" >
                            <img alt="NeoImpact" width="144" height="36" loading="lazy" src={logo} />
                        </div>
                        <div className="">
                            <p className="mt-4">Benefits of NeoImpact Databook account: </p>
                            <ul className="">
                                <li className="mt-4">
                                    <BsCheckCircle size={16} className='me-2' /> Access to ESG fundamental datasets
                                </li>
                                <li className="mt-3">
                                    <BsCheckCircle size={16} className='me-2' /> Monitor ESG regulatory landscape
                                </li>
                                <li className="mt-3">
                                    <BsCheckCircle size={16} className='me-2' /> Assess industry innovation quotient
                                </li>
                                <li className="mt-3">
                                    <BsCheckCircle size={16} className='me-2' /> Track reputational risk 
                                </li>
                                <li className="mt-3">
                                    <BsCheckCircle size={16} className='me-2' /> Leverage ESG advisory solutions
                                </li>
                                <li className="mt-3">
                                    <BsCheckCircle size={16} className='me-2' /> Enable informed decision making
                                </li>
                            </ul>
                            <div className="d-block mt-6">
                                <p>Already have an account?<a  onClick={() => navigate("/sign-in#")}  className="text-decoration-underline  fw-semibold">{' Sign in '}</a>here.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Modal>;


}
