import React, { useEffect } from "react";
import AuthLayout from "./AuthLayout";
import DynamicMeta from "../../component/DynamicMeta";
 import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setPageTitle } from "../../store/commonSlice";
 import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccessForm from "../../component/Forms/AccessForm";

const Access = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { isLoggedIn } = useSelector(({ auth }) => auth);

    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Access Form"))
    }, [dispatch])

    // Redirect to dashboard if logged in
    useEffect(() => {
        if (isLoggedIn) {
            // navigate("/dashboard")
        }
    }, [isLoggedIn, navigate]);

    return (
        <>
            <DynamicMeta />
            <AuthLayout>
                <div className="row h-100 justify-content-center align-content-center">
                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-9 position-relative">
                        <div className="card card-border my-6">
                            <div className="card-body p-5">
                                    <AccessForm />
                            </div>
                        </div>
                    </div>
                </div>
            </AuthLayout>
        </>
    );
};

export default Access;
