import { CommonResponseState } from "../types/commonTypes";
import { AppDispatch } from "../store";
import { setCompanies, setCompaniesDropdown, setCompanyDetails, setContinentDropdown, setCountriesDropdown, setLoading } from "../store/companySlice";
import { fetcherGet } from "../utils/fetcher";
import { toast } from "react-toastify";

export function getCompanies({ q, page, limit }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'companies', loading: true }));
        fetcherGet(`companies?q=${q}&page=${page}&limit=${limit}`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setCompanies({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)
            }).finally(() => {
                dispatch(setLoading({ key: 'companies', loading: false }));
            });
    };
}
export function getCompaniesDropdown({ q, categoryId, limit }: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'companiesDropdown', loading: true }));
        fetcherGet(`company-dropdown?q=${q?.name || q}&category_id=${categoryId}&limit=${limit}`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setCompaniesDropdown({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'companiesDropdown', loading: false }));
            });
    };
}

export function getCompanyDetail(id: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'company', loading: true }));
        fetcherGet(`company/${id}`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setCompanyDetails({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'company', loading: false }));
            });
    };
}

export function getCompaniesSearch({ q, categoryId, limit }: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'companies', loading: true }));
        fetcherGet(`company-dropdown?q=${q}&category_id=${categoryId}&limit=${limit}`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setCompanies({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'companiesDropdown', loading: false }));
            });
    };
}

export function getCountryDropdown({ q, limit }: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'countriesDropdown', loading: true }));
        fetcherGet(`country-dd?q=${q}&limit=${limit}`)
            .then((response: CommonResponseState) => {
                console.log("response",response)
                if (response.status === 200) {
                    dispatch(setCountriesDropdown({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'countriesDropdown', loading: false }));
            });
    };
}

export function getContinentDropdown({ q, limit }: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'continentDropdown', loading: true }));
        fetcherGet(`continent-dd?q=${q}&limit=${limit}`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setContinentDropdown({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'continentDropdown', loading: false }));
            });
    };
}