import React, { useState } from 'react';
import './CountryCodeInput.css';

function CountryCodeInput({ onChangeNumber, onChangeCountryCode, defaultValue = '', defaultCountryCode = '', disabled = false, inputClassName = "", ccFieldClassName ="" }) {

  const [countryCode, setCountryCode] = useState(defaultCountryCode ? defaultCountryCode.toString() : '');
  const [phoneNumber, setPhoneNumber] = useState(defaultValue ?  defaultValue.toString() : '');
  const [validationMessage, setValidationMessage] = useState('');
 
  const handlePhoneNumberChange = (event) => {
    onChangeNumber(event.target.value);
    setPhoneNumber(event.target.value);
    setValidationMessage('');
  };

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
    onChangeCountryCode(event.target.value);
    setValidationMessage('');
  };

  return (
    <>
      <div className="form_row country_select_full">
        <div className="country_select">
          <input id="phoneSelector-req" name="data[Lead][phone_ext]" type="text"
            value={countryCode || defaultCountryCode}
            onChange={handleCountryCodeChange}
            placeholder="+91"
            disabled={disabled}
            className={`form_input form-control  ${disabled ? "phone-disable" : ""}  ${inputClassName ? inputClassName : 'bg-transparent'} ${ccFieldClassName} w-100`} />
        </div>
        <div className="input text required">
          <input name="data[Lead][mobile_number]" id="LeadPhoneNo" type="text"
            value={phoneNumber || defaultValue}
            onChange={handlePhoneNumberChange}
            placeholder="Phone Number*"
            disabled={disabled}
            className={`form_input form-control  ${disabled ? "phone-disable" : ""}  ${inputClassName ? inputClassName : 'bg-transparent'} w-100`} />
        </div>
      </div>
      {validationMessage && <span className='text-danger fs-8'>{validationMessage}</span>}
    </>
  );
}

export default CountryCodeInput;
