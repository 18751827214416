import React from 'react';

import ErrorLanding from './ErrorLanding';

export default function NotFound() {

    return (
        <div>
            <ErrorLanding></ErrorLanding>
        </div>
    )

}