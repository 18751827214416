import React, { useState } from "react";
import Layout from "../../layouts/dashboard/Layout";
import DynamicMeta from "../../component/DynamicMeta";
import downloadImage  from "../../assets/img/download.svg";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ImageModal = ({ imageSrc, fileName, handleClose ,show }) => (


<Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
  >
  <Modal.Header >
    <Modal.Title>{fileName}</Modal.Title>
  </Modal.Header>

  <Modal.Body>
  <img src={imageSrc} alt={fileName} style={{ maxWidth: '100%', height: 'auto' }} />
  </Modal.Body>

  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>Close</Button>
  </Modal.Footer>
</Modal>

);

function CompanyLocations() {


  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [fileName, setFileName] = useState('');

  const handleShowImage = (event, fileName) => {
    event.preventDefault();
    console.log('fileName',fileName);
    const imageUrl = `${process.env.PUBLIC_URL}/pdf/sample-pdf-file.png`;
    setImageSrc(imageUrl);  // Set the image source
    setFileName(fileName);  // Set the file name to display in modal
    setShowModal(true);     // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false);    // Hide the modal
  };

  return (
    <>
      <DynamicMeta />
      <Layout>
        <div className="hk-pg-wrapper pb-10">
          <div className="container-fluid px-4">
            <div className="hk-pg-body pt-2 pb-14">
              <section className="my_portfolio">
                <div className="row">
                  <div className="col-lg-12">
                    <h1 className="text-black fw-semibold mb-3 h4">
                      Musandam Power Co.
                    </h1>
                    {showModal && (
                        <ImageModal imageSrc={imageSrc} fileName={fileName} handleClose={handleCloseModal} show={showModal} />
                      )}
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-3 col-md-5 col-12">
                            <div
                              className="nav flex-column nav-pills border bg-white rounded"
                              id="v-pills-tab"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <div className="nav-link">
                                <h5 className="text_color_3 fw-semibold mb-0">
                                  Geographical Presence
                                </h5>
                              </div>
                              <button
                                className="nav-link active"
                                id="location-tab-1"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-oman"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-oman"
                                aria-selected="true"
                              >
                                <div className="compeny_logo">O</div>Oman
                              </button>
                              <button
                                className="nav-link"
                                id="location-tab-2"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-uae"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-uae"
                                aria-selected="false"
                              >
                                <div className="compeny_logo">U</div>United Arab
                                Emirates
                              </button>
                              <button
                                className="nav-link"
                                id="location-tab-3"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-saudi"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-saudi"
                                aria-selected="false"
                              >
                                <div className="compeny_logo">S</div>Saudi
                                Arabia
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-9 col-md-7 col-12">

                          <div className="row mb-4">
                            <div className="col-12">
                                <div className="companies_filter border bg-white rounded p-4">
                                  <div className="col-12">
                                      <h5 className="text-black fw-semibold mb-3">Filter by tags</h5>
                                  </div>
                                  <div className="row">
                                  <ul className="d-flex gap-3"><li><a href="#" className="tag_1">Environment</a></li><li><a href="#" className="tag_1">Social</a></li><li><a href="#" className="tag_1">Governance</a></li></ul>
                                  </div>
                                </div>
                            </div>
                          </div>

                         

                          <div className="tab-content mt-md-0" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-oman" role="tabpanel" aria-labelledby="location-tab-1" tabIndex={0}>
                                <div className="table-responsive">
                                  <table className="table table-bordered list-view">
                                      <thead>
                                        <tr>
                                            <th>S.N.</th>
                                            <th>Regulation Title</th>
                                            <th>Regulation Summary</th>
                                            <th>Issuer authority</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                              <a href="">
                                              ISSUING THE LAW ON CONSERVATION OF THE ENVIRONMENT AND PREVENTION OF POLLUTION
                                              </a>
                                              <div>
                                              <a href="#" onClick={(event) => handleShowImage(event, 'ISSUING THE LAW ON CONSERVATION OF THE ENVIRONMENT AND PREVENTION OF POLLUTION.png')}>
                                              <img alt="download-icon" width="20" height="20"  src={downloadImage} />
                                              </a>
                                              </div>
                                            </td>
                                            <td>
                                              Law on Conservation of the Environment and Prevention of Pollution in Oman.
                                              <span className="badge badge-soft-primary">Environment </span> 
                                            </td>
                                            <td>Government (Sultan of Oman)</td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                              <a href="">
                                              Issuing The Law On Nature Reserves And Wildlife Conservation.
                                              </a>
                                              <div>
                                              <a href="#" onClick={(event) => handleShowImage(event, 'Issuing The Law On Nature Reserves And Wildlife Conservation.png')}>
                                              <img alt="download-icon" width="20" height="20"  src={downloadImage} />
                                              </a>
                                              </div>
                                            </td>
                                            <td>
                                              Law on Nature Reserves and Wildlife Conservation issued by Sultan Qaboos bin Said of Oman, establishing regulations for the protection of natural habitats and wildlife. It defines key terms, responsibilities of the Ministry of Regional Municipalities, Environment and Water Resources, and the establishment of nature reserves. The law includes provisions for environmental assessments, management plans, and penalties for violations related to wildlife conservation. It aims to promote public awareness and ensure the sustainable use of natural resources while protecting biodiversity.
                                              <span className="badge badge-soft-primary">Environment </span>
                                            </td>
                                            <td>
                                              Government (Sultan of Oman)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>
                                              <a href="">
                                              REGULATIONS FOR THE MANAGEMENT OF HAZARDOUS WASTE
                                              </a>
                                              <div>
                                              <a href="#" onClick={(event) => handleShowImage(event, 'REGULATIONS FOR THE MANAGEMENT OF HAZARDOUS WASTE.png')}>
                                              <img alt="download-icon" width="20" height="20"  src={downloadImage} />
                                              </a>
                                              </div>
                                            </td>
                                            <td>
                                              Laws Pertaining to Cyber Security and data breach.
                                              <span className="badge badge-soft-primary">Governance (Cyber Security) </span>
                                            </td>
                                            <td>
                                              Government
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                              <a href="">
                                              Issuing the Cyber Crime Law
                                              </a>
                                              <div>
                                              <a href="#" onClick={(event) => handleShowImage(event, 'Issuing the Cyber Crime Law.png')}>
                                              <img alt="download-icon" width="20" height="20"  src={downloadImage} />
                                              </a>
                                              </div>
                                            </td>
                                            <td>
                                              Labour Law in Oman, repealing the previous law and empowering the Minister of Manpower to issue implementing regulations; it applies to all employers and workers, except those governed by special laws or systems.
                                              <span className="badge badge-soft-primary">Social (Human Rights)
                                              </span>
                                            
                                            </td>
                                            <td>
                                              Government
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>
                                              <a href="">
                                              ISSUING THE LABOUR LAW
                                              </a>
                                              <div>
                                              <a href="#" onClick={(event) => handleShowImage(event, 'Issuing the Cyber Crime Law.png')}>
                                              <img alt="download-icon" width="20" height="20"  src={downloadImage} />
                                              </a>
                                              </div>
                                            </td>
                                            <td>
                                              This document contains laws about Law on Combating Money Laundering and Terrorism Financing in country of Oman 

                                              <span className="badge badge-soft-primary">Governance  

                                              </span>
                                            </td>
                                            <td>
                                              Government
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td>
                                              <a href="">
                                              Promulgating the Law on Combating Money Laundering and Terrorism Financing
                                              </a>
                                              <div>
                                              <a href="#" onClick={(event) => handleShowImage(event, 'Promulgating the Law on Combating Money Laundering and Terrorism Financing.png')}>
                                              <img alt="download-icon" width="20" height="20"  src={downloadImage} />
                                              </a>
                                              </div>
                                            </td>
                                            <td>
                                              The Public Authority for Electricity and Water (PAEW) is tasked with executing the government's privatization policy for the electricity and water sectors. This includes directing the Electricity Holding Company (EHC) to sell shares or dispose of assets, facilitating privatization of government-owned companies, and allowing private sector investment in the sector.

                                              <span className="badge badge-soft-primary">Environment
  

                                              </span>

                                            </td>
                                            <td>
                                              Government
                                            </td>
                                        </tr>
                                      </tbody>
                                  </table>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-uae" role="tabpanel" aria-labelledby="location-tab-2" tabIndex={1}>
                                <div className="table-responsive">
                                  <table className="table table-bordered list-view">
                                      <thead>
                                        <tr>
                                            <th>S.N.</th>
                                            <th>Regulation Title</th>
                                            <th>Regulation Summary</th>
                                            <th>Issuer authority</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                              <a href="">
                                              Ministerial Decree No. (98) of 2019 On using Refuse Derived Fuel (RDF) produced from waste treatment procedures in cement plants

                                              </a>
                                            </td>
                                            <td>
                                            A regulation on waste treatment in cement factories set up by the UAE government
                                            <span className="badge badge-soft-primary">Environment (Waste)</span>
                                            </td>
                                            <td>Federal Law, UAE
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                              <a href="">
                                              ON THE INTEGRATED WASTE MANAGEMENT

                                              </a>
                                            </td>
                                            <td>
                                            This Decree-Law defines key terms for domestic employment in the UAE, including "State" (UAE), "Ministry" (Ministry of Human Resources and Emiratisation), and "Domestic Worker" (individuals performing household services for pay). It also describes the roles of employers and recruitment agencies, as well as the concept of direct recruitment of domestic workers outside the agency's registered list.
                                            <span className="badge badge-soft-primary">Environment (Waste)</span>
                                            </td>
                                            <td>
                                            Federal Law, UAE

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>
                                              <a href="">
                                              Ministerial Decision No. (849) for the year 2010 on the amendment of the Ministerial Decision No. (554) forthe year 2009 concerning the prohibited and restricted use pesticides in the United Arab Emirates

                                              </a>
                                            </td>
                                            <td>
                                            This refers to various federal laws and cabinet resolutions related to pesticides and environmental protection in the UAE, including laws on fraud, agricultural quarantine, and pesticide certification. It emphasizes the prohibition of harmful pesticides due to their adverse effects on health and the environment, supported by international technical reports. The decisions aim to regulate pesticide manufacture, importation, and circulation, reflecting the public interest and environmental conservation.
                                            <span className="badge badge-soft-primary">Environment</span>
                                            </td>
                                            <td>
                                            Federal Law, UAE

                                            </td>
                                        </tr>
                                        
                                        <tr>
                                            <td>4</td>
                                            <td>
                                              <a href="">
                                              On the Protection and Development of the Environment

                                              </a>
                                            </td>
                                            <td>
                                            This contains key definitions for environmental governance in the UAE, including terms such as State (United Arab Emirates), Agency (Federal Environmental Agency), and Competent Authorities (local authorities in each Emirate). It defines the Environment as both natural and artificial elements, and describes the Marine and Aquatic Environments, as well as Natural Reserves, which are protected areas with significant ecological value.
                                            <span className="badge badge-soft-primary">Environment

                                            </span>
                                            </td>
                                            <td>
                                            Federal Law, UAE

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>
                                              <a href="">
                                              CONCERNING DOMESTIC WORKERS AND ITS AMENDMENTS

                                              </a>
                                            </td>
                                            <td>
                                            This Decree-Law defines key terms for domestic employment in the UAE, including "State" (UAE), "Ministry" (Ministry of Human Resources and Emiratisation), and "Domestic Worker" (individuals performing household services for pay). It also describes the roles of employers and recruitment agencies, as well as the concept of direct recruitment of domestic workers outside the agency's registered list.
                                            <span className="badge badge-soft-primary">Social

</span>
                                            </td>
                                            <td>
                                            UAE Ministry of Labour and Workers

                                            </td>
                                        </tr>
                                      </tbody>
                                  </table>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-saudi" role="tabpanel" aria-labelledby="location-tab-3" tabIndex={2}>
                                <div className="table-responsive">
                                  <table className="table table-bordered list-view">
                                      <thead>
                                        <tr>
                                            <th>S.N.</th>
                                            <th>Regulation Title</th>
                                            <th>Regulation Summary</th>
                                            <th>Issuer authority</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>
                                              <a href="">
                                              Labor and Workmen Law

                                              </a>
                                            </td>
                                            <td>
                                            The Labor Law regulates employment contracts where individuals work for employers in exchange for wages, including apprenticeships and public sector workers. Exceptions include family enterprise workers, certain agricultural workers, and domestic servants. The law prohibits violations of workers' rights and allows the Minister of Labor to extend provisions to establishments employing juveniles or women.

                                            </td>
                                            <td>Governemnt
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>
                                              <a href="">
                                              Combating Money Laundering

                                              </a>
                                            </td>
                                            <td>
                                            The law defines money laundering as concealing the source of illegally obtained funds and outlines "funds" as all types of assets with ownership documentation. It identifies "proceeds" as funds acquired through crime, "means" as tools for committing crimes, and "financial and non-financial institutions" as entities engaged in economic activities.

                                            </td>
                                            <td>
                                            Governemnt

                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td>
                                              <a href="">
                                              Executive Regulation For Air Quality

                                              </a>
                                            </td>
                                            <td>
                                            The article defines key terms in the Executive Regulation for Air Quality, including "Law," "Ministry," and "Center." It outlines roles such as "Permittee" and "Licensee," which refer to individuals or entities granted permissions for environmental activities. "Activity" encompasses projects with potential environmental impacts, while "Environment" includes all natural surroundings and processes.

                                            </td>
                                            <td>
                                              Government
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td>
                                              <a href="">
                                              Environmental Law

                                              </a>
                                            </td>
                                            <td>
                                            Environmental rules and regulations set by Ministry of Environment, Water, and Agriculture

                                            </td>
                                            <td>
                                              Government
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td>
                                              <a href="">
                                              Executive Regulations of Law of Treated Sewage Water and its Reuse Thereof

                                              </a>
                                            </td>
                                            <td>
                                            This regulation addresses the technical controls and conditions for sewage water, including its treatment and reuse, in accordance with Article 5 of Cabinet Resolution No. 42 dated 11/2/1421 HD, Article 32 of the Law on Treated Sewage Water and its Reuse, and Royal Decree No. 7/b/302194 dated 4/7/1424 HD.

                                            </td>
                                            <td>
                                            Kingdom of Saudi Arabia

                                            </td>
                                        </tr>
                                        
                                      </tbody>
                                  </table>
                                </div>
                            </div>
                          </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </section>

             
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default CompanyLocations;
