import React from "react";

function TopEsgStories() {

    return (
        <section>
            <div className="row">
                <div className="col-md-12 col-12 col-sm-6 mt-4 mt-lg-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="border bg-info bg-opacity-10 rounded-top p-2">
                                <h5 className="text-black fw-semibold mb-0 text-center"> Top ESG News</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" style={{ height: "68vh", overflowY: "scroll" }}>
                        <div className="col-12">
                            <div className="sectors-esg-stories">
                                {
                                    // Array(6).fill(<ShimmerThumbnail height="75" />)
                                    Array(5).fill( <>
                                    <div className="col-12">
                                        <div className="border bg-white bg-opacity-10 px-3 py-2">
                                            <div className="row">
                                                <div className="col-lg-2 col-3">
                                                    <img src="img/thumbnailImage.png" alt="img" />
                                                </div>
                                                <div className="col-lg-10 col-9">
                                                    <h6 className="fw-semibold text-black">Lorem ipsum dolor sit amet, consectetuer</h6>
                                                    <p>adipiscing elit. Aenean...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="border bg-info bg-opacity-10 px-3 py-2">
                                            <div className="row">
                                                <div className="col-lg-2 col-3">
                                                    <img src="img/thumbnailImage.png" alt="img" />
                                                </div>
                                                <div className="col-lg-10 col-9">
                                                    <h6 className="fw-semibold text-black">Lorem ipsum dolor sit amet, consectetuer</h6>
                                                    <p>adipiscing elit. Aenean...</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>)
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default TopEsgStories