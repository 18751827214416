
import React from 'react';

import Layout from "../../layouts/dashboard/Layout";
import DynamicMeta from "../../component/DynamicMeta";
import RegulationRow from './RegulationRow';
import FilterBy from '../FilterBy';

function RegulationList() {

  return (
    <>
      <DynamicMeta />
      <Layout >

        <div className="hk-pg-wrapper pb-10"><div className="container-fluid px-4"><div className="hk-pg-body pt-2 pb-14">
          <FilterBy
           filterBy={'Filters'}
           componentsOrder={['RegionFilter', 'CountryFilter', 'CompanyFilter', 'SectorFilter', 'YearFilter']} 
           />
          <section className="pt-5">
            <RegulationRow />
          </section>


        </div></div></div>
      </Layout>
    </>
  );
};

export default RegulationList;
