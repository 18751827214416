import React, { useEffect } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setPageTitle } from "../../store/commonSlice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUserDetails, verifyAccount } from "../../services/authService";
import VerifyForm from "../../component/Forms/VerifyForm";
import SignUpLayout from "./SignUpLayout";

const VerifyAccount = () => {
    const dispatch = useAppDispatch();
    const { token } = useParams();
    const { user } = useSelector(({ auth }) => auth);

    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Verification"))
        if (token) {
            dispatch(verifyAccount(token));
        } else {
            if (!user) {
                dispatch(getUserDetails());
            }
        }
    }, [dispatch, token,user])


    return (
        <>
            <SignUpLayout>
                <VerifyForm />
            </SignUpLayout>
        </>
    );
};

export default VerifyAccount;
