import React from "react";

import { Link } from "react-router-dom";
import logo from '../../assets/img/logo.png'


function AuthLayout({ children }) {
    return (<>
        <div className="brand_background text-start">
            <div className="hk-wrapper hk-pg-auth" data-footer="simple">
                <nav className="hk-navbar navbar navbar-expand-xl navbar-light fixed-top">
                    <div className="container-xxl">
                        <div className="col-lg-2 col-12 text-left">
                            <Link to={"/"} className="d-flex logo align-items-center">
                                <img src={logo} alt="logo" />

                            </Link>
                        </div>
                        <div className="nav-end-wrap">
                            <ul className="navbar-nav flex-row">
                                <li className="nav-item nav-link py-0"><Link to={""} title="Get Help" className="btn btn-sm btn-outline-light"
                                ><span><span className="icon"><span className="feather-icon"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                    className="feather feather-help-circle">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                </svg></span></span><span>Get Help</span></span></Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="hk-pg-wrapper d-flex pb-0">
                    <div className="container">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default AuthLayout


