import React, { useEffect } from "react";
import SigninForm from "../../component/Forms/SigninForm";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setPageTitle } from "../../store/commonSlice";
import SignUpLayout from "./SignUpLayout";

const Signin = () => {
    const dispatch = useAppDispatch();


    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Sign In"))
    }, [dispatch])

    return (
        <>
            <SignUpLayout>
                <SigninForm />
            </SignUpLayout>

        </>
    );
};

export default Signin;
