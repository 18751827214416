export const unAuthenticatedRoute = [`/sanctum/csrf-cookie`,`${process.env.REACT_APP_API_URL}/api/sign-in`,`${process.env.REACT_APP_API_URL}/api/signup`,`${process.env.REACT_APP_API_URL}/api/forgot-password`,`${process.env.REACT_APP_API_URL}/api/forgot-password` ,`${process.env.REACT_APP_API_URL}/api/reset-password`,`${process.env.REACT_APP_API_URL}/api/verify-otp`,`${process.env.REACT_APP_API_URL}/api/resend-otp`];
export const pageRangeDisplayed = 10;
export function validateEmail(email:string) {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex.test(email);
}
export function getYearsDropdown() {
  const year = 2021;//new Date().getFullYear() - 6;
  const maxYear = new Date().getFullYear() + 1;
  return Array.from({ length: maxYear - year }, (value, index) => year + index);
}

export const constants = {
  tcUrl: "https://www.grandviewresearch.com/info/terms-of-use",
  privacyUrl: "https://www.grandviewresearch.com/info/privacy-policy",
}

export function formatDate(dateString: string): string {
  // Ensure the date string is valid
  // if (!dateString) {
  //   throw new Error("Invalid date string");
  // }

  const date = new Date(dateString);

  // Check if the date is valid
  // if (isNaN(date.getTime())) {
  //   throw new Error("Invalid date");
  // }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    // hour: '2-digit',
    // minute: '2-digit',
    // second: '2-digit',
    hour12: true
  };

  return date.toLocaleString('en-US', options);
}

export const country = {
  USD: 'en-US',
  INR: 'en-IN',
  EUR: 'en-EU',
  JPY: 'ja-JP',
  GBP: 'en-GB',
  AUD: 'en-AU',
  CAD: 'en-CA',
  CHF: 'de-CH',
  CNY: 'zh-CN',
  SEK: 'sv-SE',
  NZD: 'en-NZ'
};

export const formatCurrency = ({ value, currency = 'USD' }) => {
  return new Intl.NumberFormat(country[currency], { style: 'currency', currency: currency,maximumSignificantDigits:1 }).format(value || 0);
}


// Ensure formatNumber always returns a string
export const formatNumber = (value: number | { value: string; currency: string }): string => {
  if (typeof value === 'number') {
    return new Intl.NumberFormat('en-IN').format(value);
  } else if (typeof value === 'object' && value.value && value.currency) {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: value.currency,
    }).format(Number(value.value));
  }
  
  return ''; // Return an empty string if the format doesn't match
};

// Example function that calls formatNumberFromString
export const formatNumberFromString = (value: string, currency = 'USD'): string => {
  let match = value.match(/\$\d+(\.\d{1,2})?/);

  if (match) {
    let amount = parseFloat(match[0].replace('$', ''));
    let formattedAmount = formatNumber({ value: amount.toString(), currency: currency });
    return value.replace(match[0], formattedAmount);
  }

  return value;
};
