import { CommonResponseState } from "../types/commonTypes";
import { AppDispatch } from "../store";
import { setdDeals, setDealsGraphDetails, setJobs, setJobsGraphDetails, setLoading, setNews, setPatentGraphDetails, setPatents, setRegulations } from "../store/datasetSlice";
import { fetcherGet } from "../utils/fetcher";
import { toast } from "react-toastify";

export function getPatents({ keyword, companyId, page, showRelated,limit }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'patents', loading: true }));
        fetcherGet(`patents?keyword=${keyword}&company_id=${companyId}&related=${showRelated}&page=${page}&limit=${limit}`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setPatents({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)
            }).finally(() => {
                dispatch(setLoading({ key: 'patents', loading: false }));
            });
    };
}

export function getDeals({ companyId, page, limit }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'deals', loading: true }));
        fetcherGet(`get-company-deals?company_id=${companyId}&page=${page}&limit=${limit}`)
            .then((response: CommonResponseState) => {
                if (response[0] === 200) {
                    dispatch(setdDeals({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)
            }).finally(() => {
                dispatch(setLoading({ key: 'deals', loading: false }));
            });
    };
}

export function getJobs({ companyId, from_date, to_date, page, limit }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'jobs', loading: true }));
        fetcherGet(`get-company-jobs?company_id=${companyId}&from_date=${from_date}&to_date=${to_date}&page=${page}&limit=${limit}`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setJobs({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)
            }).finally(() => {
                dispatch(setLoading({ key: 'jobs', loading: false }));
            });
    };
}

export function getNews({ companyId, year, page, limit }) {

    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'news', loading: true }));
        fetcherGet(`get-company-news?company_id=${companyId}&year=${year}&page=${page}&limit=${limit}`)
            .then((response: CommonResponseState) => {
                if (response[0] === 200) {
                    dispatch(setNews({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'news', loading: false }));

            });
    };
}

export function getRegulations({ companyId, page, limit }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'regulations', loading: true }));
        fetcherGet(`regulations?company_id=${companyId}&page=${page}&limit=${limit}`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setRegulations({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'regulations', loading: false }));

            });
    };
}


export function getJobsGraphDetails({ companyId, month, year }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'jobsGraphDetails', loading: true }));
        fetcherGet(`jobs-graph-data?company_id=${companyId}&year=${year}&month=${month}`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setJobsGraphDetails({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'jobsGraphDetails', loading: false }));
            });
    };
}

export function getDealsGraph({ companyId, sectorId, categoryId }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'dealsGraphDetails', loading: true }));
        fetcherGet(`get-deals-graph-data?sector_id=${sectorId}&subsector_id=${categoryId}&company_id=${companyId}`)
            .then((response: CommonResponseState) => {
                if (response[0] === 200) {
                    dispatch(setDealsGraphDetails({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'dealsGraphDetails', loading: false }));
            });
    };
}
export function getPatentGraph({ companyId, sectorId, categoryId }) {
    return async (dispatch: AppDispatch) => {

        dispatch(setLoading({ key: 'patentGraphDetails', loading: true }));
        fetcherGet(`get-patent-graph-data?sector_id=${sectorId}&subsector_id=${categoryId}&company_id=${companyId}`)
            .then((response: CommonResponseState) => {
                if (response?.status === 200) {
                    dispatch(setPatentGraphDetails({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'patentGraphDetails', loading: false }));
            });
    };
}