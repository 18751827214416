export const shortenedTitle = (title = '', limit, showDotSeperator = true) => {
    if (title) {
      if (title.length > limit) {
        let dots = showDotSeperator ? '...' : '';
        title = title.substring(0, limit) + dots;
      }
    }
    return title;
  }

  
export const filterChapter = (label) => {
    let mapObj = { CHAPTER: "", Chapter: "" };
    label = label.replace(/CHAPTER|Chapter/gi, function (matched) {
      return mapObj[matched];
    });
    // Regular expression to match numbers like "01", "1.1", 1 etc.
    const regex = /(\b0+\d+(\.\d+)?\b)|(\b\d+\.\d+\.?\b)|(\b\d?\b)/g;
    const filtered = label.replace(regex, '');
    return filtered;
  }

  export const countryNameConversion = (title) => {
    if (title) {
      title = title.replace('Mexico - Latin America', 'Mexico');
      title = title.replace('Mexico - North America', 'Mexico');
      title = title.replace('Turkey - Europe', 'Turkey');
      title = title.replace('Turkey - Middle East & Africa', 'Turkey');
      title = title.replace('Turkey - MEA', 'Turkey');
      title = title.replace('U.K.', 'UK');
      title = title.replace('U.s.', 'U.S.')
      return title;
    } else {
      return title;
    }
  }
    
  