
import React from 'react';
import Layout from "../../layouts/dashboard/Layout";
import DynamicMeta from "../../component/DynamicMeta";

function Recentvisit() {

  return (
    <>
    <DynamicMeta />
    <Layout >
    <div className="hk-pg-wrapper pb-10">
            <div className="container-fluid px-4">
                <div className="bread-crumbs">
                    <div className="row align-items-center">
                        <div className="col-xl-10 "></div>
                        <div className="col-xl-2"></div>
                    </div>
                    <div className="row"></div>
                </div>
                <div className="hk-pg-body pt-2">
                    <header className="profile-header">
                        <ul className="nav nav-line nav-tabs nav-icon nav-light h-100 d-flex">
                            <li className="nav-item"><a className="d-flex align-items-center nav-link h-100 "
                                     title="portfolio"
                                    href="/portfolio"><span
                                        className="nav-link-text">Portfolio</span></a></li>
                            <li className="nav-item"><a className="d-flex align-items-center nav-link h-100 "
                                     title="Watchlist"
                                    href="/watchlist"><span
                                        className="nav-link-text">Watchlist</span></a></li>
                            <li className="nav-item"><a className="d-flex align-items-center nav-link h-100 active "
                                     href="/recentvisit"><span
                                        className="nav-link-text">Recent Visited</span></a></li>
                            
                        </ul>
                    </header>
                    
                    <div className="row mt-4">
                        <label className="font-s-15 fw-semibold form-label">TYPE</label>
                        <div className="col-md-3"><select className="form-select">
                                <option value="all">Regulation</option>
                                <option value="statistics">Jobs</option>
                                <option value="content">Patents</option>
                                <option value="companies">Companies</option>
                                <option value="databooks">News</option>
                                <option value="databooks">Industry</option>
                            </select></div>
                        <div className="col-md-3"><label className="font-s-15 fw-semibold form-label mr-5">Sort</label><svg
                                xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="currentColor"
                                className="bi bi-sort-up" viewBox="0 0 16 16">
                                <path
                                    d="M3.5 12.5a.5.5 0 0 1-1 0V3.707L1.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L3.5 3.707zm3.5-9a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5M7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z">
                                </path>
                            </svg></div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-mb-12 col-xl-8">
                            <div className="srch_result_col px-mb-2 px-4 py-3  ">
                                <div className="srch_result_col_top_heading font-s-11 fw-semibold">Regulation</div>
                                <div className="d-flex align-items-top mb-1">
                                    <div className="flex-shrink-0 mt-1"><svg width="24" height="24" viewBox="0 0 24 24"
                                            fill="#423547" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Icon/report">
                                                <path id="Vector"
                                                    d="M20 5V19H4V5H20ZM20 3H4C2.89 3 2 3.89 2 5V19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V5C22 3.89 21.11 3 20 3ZM18 15H6V17H18V15ZM10 7H6V13H10V7ZM12 9H18V7H12V9ZM18 11H12V13H18V11Z">
                                                </path>
                                            </g>
                                        </svg></div>
                                    <div className="flex-grow-1 ms-2 align-items-center">
                                        <a href="#">
                                            <div className="w-100 pe-2"><span>
                                                    <h5 className="mb-0 text_purple">Energy Conservation Program: Energy Conservation Standards for Residential Clothes Dryers and Room Air Conditioners</h5>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="">
                                        <a className="btn btn-soft-danger  btn-sm ms-auto remove"><svg
                                            stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 16 16" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z">
                                            </path>
                                            <path
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z">
                                            </path>
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-mb-12 col-xl-8">
                            <div className="srch_result_col px-mb-2 px-4 py-3  ">
                                <div className="srch_result_col_top_heading font-s-11 fw-semibold">Regulation</div>
                                <div className="d-flex align-items-top mb-1">
                                    <div className="flex-shrink-0 mt-1"><svg width="24" height="24" viewBox="0 0 24 24"
                                            fill="#423547" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Icon/report">
                                                <path id="Vector"
                                                    d="M20 5V19H4V5H20ZM20 3H4C2.89 3 2 3.89 2 5V19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V5C22 3.89 21.11 3 20 3ZM18 15H6V17H18V15ZM10 7H6V13H10V7ZM12 9H18V7H12V9ZM18 11H12V13H18V11Z">
                                                </path>
                                            </g>
                                        </svg></div>
                                    <div className="flex-grow-1 ms-2 align-items-center">
                                        <a href="#">
                                            <div className="w-100 pe-2"><span>
                                                    <h5 className="mb-0 text_purple">Air Quality Regulation: State of Louisiana</h5>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="">
                                        <a className="btn btn-soft-danger  btn-sm ms-auto remove"><svg
                                            stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 16 16" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z">
                                            </path>
                                            <path
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z">
                                            </path>
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-mb-12 col-xl-8">
                            <div className="srch_result_col px-mb-2 px-4 py-3">
                                <div className="srch_result_col_top_heading font-s-11 fw-semibold">Regulation</div>
                                <div className="d-flex align-items-top mb-1">
                                    <div className="flex-shrink-0 mt-1"><svg width="24" height="24" viewBox="0 0 24 24"
                                            fill="#423547" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Icon/report">
                                                <path id="Vector"
                                                    d="M20 5V19H4V5H20ZM20 3H4C2.89 3 2 3.89 2 5V19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V5C22 3.89 21.11 3 20 3ZM18 15H6V17H18V15ZM10 7H6V13H10V7ZM12 9H18V7H12V9ZM18 11H12V13H18V11Z">
                                                </path>
                                            </g>
                                        </svg></div>
                                    <div className="flex-grow-1 ms-2 align-items-center">
                                        <a href="#">
                                            <div className="w-100 pe-2"><span>
                                                    <h5 className="mb-0 text_purple">Preliminary Risk Assessment for Registration Review of Propazine</h5>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="">
                                        <a className="btn btn-soft-danger  btn-sm ms-auto remove"><svg
                                            stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 16 16" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z">
                                            </path>
                                            <path
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z">
                                            </path>
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-mb-12 col-xl-8">
                            <div className="srch_result_col px-mb-2 px-4 py-3">
                                <div className="srch_result_col_top_heading font-s-11 fw-semibold">Regulation</div>
                                <div className="d-flex align-items-top mb-1">
                                    <div className="flex-shrink-0 mt-1"><svg width="24" height="24" viewBox="0 0 24 24"
                                            fill="#423547" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Icon/report">
                                                <path id="Vector"
                                                    d="M20 5V19H4V5H20ZM20 3H4C2.89 3 2 3.89 2 5V19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V5C22 3.89 21.11 3 20 3ZM18 15H6V17H18V15ZM10 7H6V13H10V7ZM12 9H18V7H12V9ZM18 11H12V13H18V11Z">
                                                </path>
                                            </g>
                                        </svg></div>
                                    <div className="flex-grow-1 ms-2 align-items-center">
                                        <a href="#">
                                            <div className="w-100 pe-2"><span>
                                                    <h5 className="mb-0 text_purple">Air Quality Regulation: State of Louisiana (Revised)</h5>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="">
                                        <a className="btn btn-soft-danger  btn-sm ms-auto remove"><svg
                                            stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 16 16" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z">
                                            </path>
                                            <path
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z">
                                            </path>
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-mb-12 col-xl-8">
                            <div className="srch_result_col px-mb-2 px-4 py-3">
                                <div className="srch_result_col_top_heading font-s-11 fw-semibold">Regulation</div>
                                <div className="d-flex align-items-top mb-1">
                                    <div className="flex-shrink-0 mt-1"><svg width="24" height="24" viewBox="0 0 24 24"
                                            fill="#423547" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Icon/report">
                                                <path id="Vector"
                                                    d="M20 5V19H4V5H20ZM20 3H4C2.89 3 2 3.89 2 5V19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V5C22 3.89 21.11 3 20 3ZM18 15H6V17H18V15ZM10 7H6V13H10V7ZM12 9H18V7H12V9ZM18 11H12V13H18V11Z">
                                                </path>
                                            </g>
                                        </svg></div>
                                    <div className="flex-grow-1 ms-2 align-items-center">
                                        <a href="#">
                                            <div className="w-100 pe-2"><span>
                                                    <h5 className="mb-0 text_purple">Clean Air Act</h5>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="">
                                        <a className="btn btn-soft-danger  btn-sm ms-auto remove"><svg
                                            stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 16 16" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z">
                                            </path>
                                            <path
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z">
                                            </path>
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-mb-12 col-xl-8">
                            <div className="srch_result_col px-mb-2 px-4 py-3">
                                <div className="srch_result_col_top_heading font-s-11 fw-semibold">Regulation</div>
                                <div className="d-flex align-items-top mb-1">
                                    <div className="flex-shrink-0 mt-1"><svg width="24" height="24" viewBox="0 0 24 24"
                                            fill="#423547" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Icon/report">
                                                <path id="Vector"
                                                    d="M20 5V19H4V5H20ZM20 3H4C2.89 3 2 3.89 2 5V19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V5C22 3.89 21.11 3 20 3ZM18 15H6V17H18V15ZM10 7H6V13H10V7ZM12 9H18V7H12V9ZM18 11H12V13H18V11Z">
                                                </path>
                                            </g>
                                        </svg></div>
                                    <div className="flex-grow-1 ms-2 align-items-center">
                                        <a href="#">
                                            <div className="w-100 pe-2"><span>
                                                    <h5 className="mb-0 text_purple">Occupational Safety and Health Act</h5>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="">
                                        <a className="btn btn-soft-danger  btn-sm ms-auto remove"><svg
                                            stroke="currentColor" fill="currentColor" stroke-width="0"
                                            viewBox="0 0 16 16" height="1em" width="1em"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z">
                                            </path>
                                            <path
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z">
                                            </path>
                                        </svg></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </Layout>
   </> 
          

  );
};

export default Recentvisit;
