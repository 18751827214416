
import DynamicMeta from "../component/DynamicMeta";
import Layout from "../layouts/dashboard/Layout";
import React from "react";
import SigninForm from "../component/Forms/SigninForm";
import LandingLayout from "../layouts/landing/LandingLayout";
import ContactUsForm from "../component/Forms/ContactUsForm";
function ContactUs() {
   return (
      <>
         <LandingLayout>
            <div className="py-lg-10 py-5 bg_light_2">
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-lg-6">
                        <div className="me-lg-4">
                           <div className="bg-white rounded-5 p-5 rq_cutom_demo">
                              <ContactUsForm />
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-6">
                        <div className="ms-lg-4 mt-lg-0 mt-4">
                           <h1 className="text_demi text_purple mb-4">Schedule your <br />15-minute call now</h1>
                           <h5 className="lh-base mb-3">We'll customize NeoImpact Databook to suit your specific needs and answer all your questions.</h5>
                           <h5 className="lh-base">Get ready to access the latest and most relevant market information, company insights, and statistics, complete with detailed source information and download options.</h5>
                           <h4 className="text_demi my-4">Trusted by industry-leading organizations</h4>
                           <div style={{ filter: 'grayscale(100%)', opacity: '0.7' }}>
                              <ul className="d-flex flex-nowrap gap-4"><li><img src="https://cdn.grandviewresearch.com/horizon/images/client_logos_31.png" alt="logo" height="40" loading="lazy" /></li>
                                 <li><img src="https://cdn.grandviewresearch.com/horizon/images/client_logos_32.png" alt="logo" height="40" loading="lazy" /></li>
                                 <li><img src="https://cdn.grandviewresearch.com/horizon/images/client_logos_33.png" alt="logo" height="40" loading="lazy" /></li>
                                 <li><img src="https://cdn.grandviewresearch.com/horizon/images/client_logos_36.png" alt="logo" height="40" loading="lazy" /></li>
                                 <li><img src="https://cdn.grandviewresearch.com/horizon/images/client_logos_35.png" alt="logo" height="40" loading="lazy" /></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>


               </div>
            </div>
         </LandingLayout>
      </>
   )
}

export default ContactUs;
