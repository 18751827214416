import { Link, useParams } from 'react-router-dom';
import { shortenedTitle } from '../../utils';
import React, { useEffect, useMemo, useState } from 'react';
import { formatDate } from '../../utils/constant';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { getNews } from '../../services/datasetService';
import Pagination from "react-js-pagination";
import { ShimmerThumbnail } from "react-shimmer-effects";
import DataNotFound from '../DataNotFound';

function News() {
  const dispatch = useAppDispatch();
  const { companyId } = useParams();
  const [list, setList] = useState({ data: [], current_page: 1, per_page: 12, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const { news } = useSelector(({ dataset }) => dataset);
  const { filter ,refresh} = useSelector(({ common }) => common);

  const query = useMemo(() => ({
    companyId: filter?.company?._id || companyId || '',
    year: filter?.year || '',
    page: activePage,
    limit: 12
  }), [filter, companyId, activePage]);

  useEffect(() => {
    dispatch(getNews(query));
  }, [dispatch, refresh,activePage]);

  useEffect(() => {
    setActivePage(1)
  }, [])

  useEffect(() => {
    setList(news.data);
  }, [news]);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber)
  }
  return (
    <section className="mt-2">
      <div className="row">
      <div className="col-lg-8">
        {
          news?.loading ? Array(10).fill(<ShimmerThumbnail />) :
            list?.data?.map((data, index) => {
              return (
                <Link to={data?.url} target='_blank' >
                  <div className="row mt-4 justify-content-center  border  rounded p-4 mt-2 bg-white" key={`news-row-${index}`}>
                    <div className="col-lg-3 d-flex align-items-center">
                      <img src={data?.image} className="img-fluid" alt="logo" style={{height:"-webkit-fill-available"}} />
                    </div>
                    <div className="col-lg-9">
                      <h5 className="fw-semibold mb-0">{data?.title}</h5>
                      <div>
                        <span className='text-dark' title={data?.text} dangerouslySetInnerHTML={{ __html: shortenedTitle(data?.text, 200) }}></span>
                      </div>
                         <p className="mr-4 mt-4 text-secondary">Author : {data?.author || "--"} </p>
                      <p className="mt-2 text-secondary" >Language : {data?.language || "--"}</p>
                      <p className="mt-2 text-secondary">Date  : {formatDate(data?.publish_date) || "--"}</p>

                    </div>
                  </div>
                </Link>
              )
            })
            
        }
        {  (news?.data?.data?.length <= 0 && !news?.loading ) && <DataNotFound title="No results" description="Unfortunately, no related news could be found." />  }
      </div>
      </div>
      {
        list?.data?.length > 0 &&
        <div className='mt-4'>

          <Pagination
            activePage={activePage}
            itemsCountPerPage={list.per_page}
            totalItemsCount={list?.total || 0}
            pageRangeDisplayed={5}
            itemClass="page-item"
            linkClass="page-link"
            onChange={handlePageChange}

          />
        </div>
      }
    </section>
  );
};

export default News;
