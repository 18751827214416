import { formatCurrency, formatDate, formatNumber, formatNumberFromString } from '../../utils/constant';
import { shortenedTitle } from '../../utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { getDeals } from '../../services/datasetService';
import Pagination from "react-js-pagination";
import { ShimmerTable } from 'react-shimmer-effects';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DataNotFound from '../DataNotFound';
import { ShimmerThumbnail } from "react-shimmer-effects";
import { updateFilter } from '../../store/commonSlice';

function Deals({pagination=true}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { companyId } = useParams();

  const [list, setList] = useState({ fund: { data: [], current_page: 1, per_page: 10, total: 0 }, investment: { data: [], current_page: 1, per_page: 10, total: 0 },investor: { data: [], current_page: 1, per_page: 10, total: 0 } });
  const [activePage, setActivePage] = useState(1);
  const { deals } = useSelector(({ dataset }) => dataset);
  const { company } = useSelector(({ company }) => company);
  const { filter ,refresh} = useSelector(({ common }) => common);
  const query = useMemo(() => ({
    companyId: filter?.company?._id || companyId || '', year: filter?.year || '', page: activePage, limit: 10
  }), [filter, companyId, activePage])

  useEffect(() => {
    dispatch(getDeals(query));
  }, [dispatch, refresh,activePage]);

  useEffect(() => {
    setActivePage(1)
  }, [])

  useEffect(() => {
    setList(deals.data);
  }, [deals]);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber)
  }
  return (
    <section className="mt-5" id="deals">
      {pagination && (<div className="row">
        {
          deals?.loading ? Array(2).fill(<div className="col-sm-2"><ShimmerThumbnail height="100" width="150" /></div>) :
            <>
              <div className="col-sm-2">
                <div className="rounded card ">
                  <div className="card-body">
                    <div className="card-title h5">Funds</div>
                    <p className="card-text">{formatNumber(deals?.data?.fund?.total || 0)}</p>
                  </div>
                </div>
              </div>

              <div className="col-sm-2">
                <div className="rounded card ">
                  <div className="card-body">
                    <div className="card-title h5">Investment</div>
                    <p className="card-text">{formatNumber(deals?.data?.investment?.total || 0)}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="rounded card ">
                  <div className="card-body">
                    <div className="card-title h5">Investors</div>
                    <p className="card-text">{formatNumber(deals?.data?.investor?.total || 0)}</p>
                  </div>
                </div>
              </div>
            </>
        }
      </div >)}
      <div className="row">

        {
          deals?.fund?.loading ? <ShimmerTable row={10} col={7} /> :
            <div className="card card-border mb-4 table_layout2">
              <div className="card-header card-header-action border-bottom-0 table_header p-2"><h5>Funding Rounds</h5></div>
              <div className="position-relative">
                <div className="table-responsive mt-0">
                  <table className="table-bordered mb-0 table">
                    <thead className="">
                      <tr>
                        <th scope="col">Announced Date</th>
                        <th scope="col">Transaction Name</th>
                        <th scope="col">Description</th>
                        <th scope="col">Number of Investors</th>
                        <th scope="col">Money Raised</th>
                        <th scope="col">Lead Investors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        list?.fund?.data?.map((data, index) => {
                          return (
                            <tr>
                              <td>{formatDate(data?.announced_on) || '--'}</td>
                              <td>{data?.identifier?.value || '--'}</td>
                              <td title={data?.short_description}>{shortenedTitle(formatNumberFromString(data?.short_description) || '--', 150)}</td>
                              <td>{data?.num_investors || '--'}</td>
                              <td>{formatCurrency(data?.funded_organization_funding_total || '') || '--'}</td>
                              <td>{data?.is_lead_investor === true ? "Yes" : "No"}</td>
                            </tr>
                          )
                        })
                      }
                      {
                        (deals?.data?.fund?.data?.length <= 0 && !deals?.loading) && <tr ><td colSpan={6}><DataNotFound title="No results" description="Unfortunately, no related fund could be found." /></td></tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        }

      </div>
      <div className="row">
        {
          deals?.investor?.loading ? <ShimmerTable row={10} col={7} /> :
            <div className="card card-border mb-4 table_layout2">
              <div className="card-header card-header-action border-bottom-0 table_header p-2"><h5>Investors</h5></div>
              <div className="position-relative">
                <div className="table-responsive mt-0">
                  <table className="table-bordered mb-0 table">
                    <thead className="">
                      <tr>
                        <th scope="col">Announced Date</th>
                        <th scope="col">Investor Name	</th>
                        <th scope="col">Funding Round	</th>
                        <th scope="col">Partners	</th>
                        <th scope="col">Lead Investors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        list?.investor?.data?.map((data, index) => {
                          return (
                            <tr>
                              <td>{formatDate(data?.announced_on) || '--'}</td>
                              <td>{data?.identifier?.value || '--'}</td>
                              <td>{data?.funding_round_identifier?.value || '--'}</td>
                              <td>{data?.partner_identifiers?.map((partner, index)=>{
                               return <>{partner?.value} ,</>

                              }) || '--'}</td>
                              <td>{data?.is_lead_investor === true ? "Yes" : "No"}</td>
                            </tr>
                          )
                        })
                      }
                      {
                        (deals?.data?.investor?.data?.length <= 0 && !deals?.loading) && <tr ><td colSpan={5}><DataNotFound title="No results" description="Unfortunately, no related invester could be found." /></td></tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        }

      </div>
      <div className="row">
        {
          deals?.investment?.loading ? <ShimmerTable row={10} col={7} /> :
            <div className="card card-border mb-4 table_layout2">
              <div className="card-header card-header-action border-bottom-0 table_header p-2"><h5>Investment</h5></div>
              <div className="position-relative">
                <div className="table-responsive mt-0">
                  <table className="table-bordered mb-0 table">
                    <thead className="">
                      <tr>
                        <th scope="col">Announced Date</th>
                        <th scope="col">Organization  Name	</th>
                        <th scope="col">Funding Round</th>
                        <th scope="col">Money Raised</th>
                        <th scope="col">Lead Investors</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        list?.investment?.data?.map((data, index) => {
                          return (
                            <tr>
                              <td>{formatDate(data?.announced_on) || '--'}</td>
                              <td>{data?.organization_identifier?.value || '--'}</td>
                              <td title={data?.funding_round_identifier?.value}>{shortenedTitle(data?.funding_round_identifier?.value || '--', 150)}</td>
                              <td>{data?.funding_round_money_raised?.currency === 'USD' && '$'}{data?.funding_round_money_raised?.value}</td>
                              <td>{data?.is_lead_investor === true ? "Yes" : "No"}</td>
                            </tr>
                          )
                        })
                      }
                      {
                        (deals?.data?.investment?.data?.length <= 0 && !deals?.loading) && <tr ><td colSpan={5}><DataNotFound title="No results" description="Unfortunately, no related invester could be found." /></td></tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        }

      </div>
      <div className="row text-end">

      {
        (!pagination && (list?.fund?.total > list?.investment?.total ? list?.fund?.total : list?.investment?.total) > 0) && 
        <p className='text-right text-info text-decoration-underline'  
        onClick={()=>{
          dispatch(updateFilter({ ...filter, company: { _id: company?.data?._id, name: company?.data?.name } }))
          navigate(`/dataset/deals`);
      }}
        >
          View All Deals </p>
      }
      </div>
      {
        ((list?.fund?.total > list?.investment?.total ? list?.fund?.total : list?.investment?.total) > 0 && pagination ) &&

        <div className='mt-4'>

          <Pagination
            activePage={activePage}
            itemsCountPerPage={list.fund?.per_page}
            totalItemsCount={list?.fund?.total > list?.investment?.total ? list?.fund?.total : list?.investment?.total}
            pageRangeDisplayed={5}
            itemClass="page-item"
            linkClass="page-link"
            onChange={handlePageChange}

          />
        </div>
      }
    </section >
  );
};

export default Deals;
