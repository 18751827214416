import React, { useEffect } from "react";
import SignUpLayout from "./SignUpLayout";
import { setPageTitle } from "../../store/commonSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import SignupForm from "../../component/Forms/SignupForm";
import AccessModel from "../../component/Models/AccessModel";

const Signup = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Sign Up"))
    }, [dispatch])



    return (<>
        {/* <DynamicMeta /> */}
        <SignUpLayout title="Create your Neo Impact account">
            <SignupForm />
        </SignUpLayout>
        <AccessModel />

    </>

    )
}

export default Signup;

