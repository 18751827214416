import { formatDate, formatNumber } from '../../utils/constant';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import { getPatents } from '../../services/datasetService';
import Pagination from "react-js-pagination";
import { ShimmerTable, ShimmerThumbnail } from "react-shimmer-effects";
import { Link, useNavigate, useParams } from 'react-router-dom';
import DataNotFound from '../DataNotFound';
import { updateFilter } from '../../store/commonSlice';
// import { ShimmerTable,ShimmerThumbnail } from '../ShimmerUI';

function Patent({ pagination = true }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const [list, setList] = useState({ data: [], current_page: 1, per_page: 10, total: 0 });
  const [activePage, setActivePage] = useState(1);
  const { patents } = useSelector(({ dataset }) => dataset);
  const { filter, refresh } = useSelector(({ common }) => common);
  const { company } = useSelector(({ company }) => company);
  const query = useMemo(() => ({
    keyword: filter?.keyword || '',
    companyId: filter?.company?._id ||  companyId || '',
    showRelated: filter?.showRelated || false,
    page: activePage,
    limit: 10
  }), [filter, companyId, activePage]);

  useMemo(() => {
    dispatch(getPatents(query));
  }, [dispatch, refresh, activePage]);

  // useEffect(() => {
  //   setActivePage(1)
  // }, [,])

  useEffect(() => {
    setList(patents.data);
  }, [patents]);

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber)
  }

  return (
    <section className="mt-5" id="patent">
      <div className="row">
        {
          pagination && ((patents?.loading) ? Array(6).fill(null).map((_, index) => (<div className="col-sm-2" key={index}> <ShimmerThumbnail height={100} width={150} /></div>)) :
            <>
              <div className="col-sm-2">
                <div className="rounded card ">
                  <div className="card-body">
                    <div className="card-title h5">Patent Records</div>
                    <p className="card-text">{formatNumber(patents?.data?.total || 0)}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="rounded card">
                  <div className="card-body">
                    <div className="card-title h5">Simple Families</div>
                    <p className="card-text">0</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="rounded card">
                  <div className="card-body">
                    <div className="card-title h5">Extended Families</div>
                    <p className="card-text">0</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="rounded card">
                  <div className="card-body">
                    <div className="card-title h5">Cites Patent</div>
                    <p className="card-text">0</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="rounded card">
                  <div className="card-body">
                    <div className="card-title h5">Cites By Patent</div>
                    <p className="card-text">0</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="rounded card">
                  <div className="card-body">
                    <div className="card-title h5">Patent Citation</div>
                    <p className="card-text">0</p>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </div>

      <div className="row">
        {
          patents?.loading ? <ShimmerTable row={10} col={7} /> :
            <div className="card card-border mb-4 table_layout2">
              <div className="card-header card-header-action border-bottom-0 table_header p-2"><h5>Patent</h5></div>

              <div className="position-relative">
                <div className="table-responsive mt-0">
                  <table className="table-bordered mb-0 table">
                    <thead className="">
                      <tr>
                        <th scope="col">Publication Identifier</th>
                        <th scope="col">Type	</th>
                        <th scope="col">Document Type	</th>
                        <th scope="col">Title</th>
                        <th scope="col">Classification-CPC</th>
                        <th scope="col">Publication Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        patents?.data?.data?.map((
                          { patent_number, patent_type, patent_title, cpcs, updatedAt },
                          index: number) => {
                          return (
                            <tr>
                              <td style={{ width: "10%" }}><span dangerouslySetInnerHTML={{ __html: patent_number }}></span></td>
                              <td style={{ width: "10%" }}><span dangerouslySetInnerHTML={{ __html: patent_type }}></span></td>
                              <td style={{ width: "10%" }}><span dangerouslySetInnerHTML={{ __html: "Grant" }}></span></td>
                              <td style={{ width: "40%" }}><span dangerouslySetInnerHTML={{ __html: patent_title }}></span></td>
                              <td style={{ width: "30%" }}><span dangerouslySetInnerHTML={{ __html: cpcs }}></span></td>
                              <td style={{ width: "10%" }}><span dangerouslySetInnerHTML={{ __html: formatDate(updatedAt) }}></span></td>

                            </tr>
                          )
                        })
                      }
                      {
                        (patents?.data?.data?.length <= 0 && !patents?.loading) && <tr ><td colSpan={6}><DataNotFound title="No results" description="Unfortunately, no related patent could be found." showRelatedBtn={true} /></td></tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        }
        {/* {(deals?.data?.data?.length <= 0 && !deals?.loading) && <DataNotFound title="No results" description="Unfortunately, no related deal could be found." />} */}

      </div>
      <div className="row text-end">
        {
          (!pagination && list?.data?.length > 5) &&
          <p className='text-right text-info text-decoration-underline'
            onClick={() => {
              dispatch(updateFilter({ ...filter, company: { _id: company?.data?._id, name: company?.data?.name } }))
              navigate(`/dataset/patent`);
            }}
          >
            View All Patent </p>
        }
      </div>

      {
        (list?.data?.length > 0 && pagination) &&
        <div className='mt-4'>
          {/* {parseInt(patents?.data?.total)} */}
          <Pagination
            activePage={activePage}
            itemsCountPerPage={patents?.data?.per_page || 0}
            totalItemsCount={patents?.data?.total || 0}
            pageRangeDisplayed={5}
            itemClass="page-item"
            linkClass="page-link"
            onChange={handlePageChange}

          />
        </div>
      }
    </section>

  );
};

export default Patent;
