import { CommonResponseState } from "../types/commonTypes";
import { AppDispatch } from "../store";
import { fetcherPost } from "../utils/fetcher";
import { toast } from "react-toastify";
import { setLoader } from "../store/commonSlice";

export function contactUs(detail: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoader(true))
        return new Promise((resolve, reject) => {
            fetcherPost(`contact-us`, detail)
                .then((response: CommonResponseState) => {
                    toast.success(response?.message)
                    resolve(response)
                })
                .catch((error) => {
                    toast.error(error?.response?.data?.message || error?.message)
                    reject(error)
                }).finally(() => {
                    dispatch(setLoader(false))
                });
        });
    };
}

