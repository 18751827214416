import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/img/logo.png'
import { useSelector } from "react-redux";
import UserProfile from "../dashboard/Header/UserProfile";
import { setAccessShow} from "../../store/commonSlice";
function LandingHeader() {
    const { user } = useSelector(({ auth }) => auth);
    const navigate = useNavigate();
    
    return (
        <header>
            <div className="gv_header hz_header_white">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-2 col-12 text-left">
                            <Link to={"/"} className="d-flex logo align-items-center">
                                <img src={logo} alt="logo" />

                            </Link>
                        </div>
                        <div className="col-lg-10 col-12 position-static gv_header_menu_res">
                            <nav className="navbar navbar-expand-lg position-static">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="nav navbar-nav ms-auto">

                                        <li className="">
                                            {
                                                (user?._id && user?.is_verified === 1)  ?
                                                           <Link to={'/dashboard'} className="" title='Home'> Home </Link>
                                                    :
                                                    <a href={"#"} onClick={() => user?.is_verified === 0 ? setAccessShow(true) : navigate("/sign-in#") } className="btn btn-primary-horizon-outline" title='Dashboard'>
                                                        {' Home'} 
                                                    </a>
                                            }
                                        </li>
                                        {user?._id  && user?.is_verified === 1?
                                            <UserProfile />
                                            :
                                            <>
                                            <li>
                                                <a href={"#"} onClick={() => navigate("/sign-in#")} className="btn btn-outline-primary" title='Log in'>
                                                    {' Log in'}
                                                </a>
                                            </li> 
                                             <li>
                                            <a  href={"#"} onClick={() => navigate("/sign-up")} className="btn btn-primary horizon-outline ms-0 text-white ms-3" title='Sign up'>
                                                {'Sign up'}
                                            </a>
                                        </li></>}

                                    </ul>
                                </div>
                            </nav>
                            <div id="suggestions" style={{ boxShadow: "rgb(136, 136, 136) 5px 10px 10px" }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default LandingHeader;