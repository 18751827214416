
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setPageTitle } from "../../store/commonSlice";
import { getUserDetails, updateAccount } from "../../services/authService";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Spinner } from "react-bootstrap";
import CountryCodeInput from "../Inputs/CountryCodeInput";
import { toast } from "react-toastify";
import { contactUs } from "../../services/contactUs";
import { useNavigate } from "react-router-dom";


export default function ContactUsForm() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(({ auth }) => auth);

    const { loader, serverError, successMessage } = useSelector(({ common }) => common);
    const defaultValues = {
        first_name: '',
        last_name: '',
        mobile_number: '',
        country_code: '+91',
        email: '',
        description: '',
        is_agree: false,
    };
    const [detail, setDetails] = useState(defaultValues);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Contact Us"))
    }, [dispatch])

    useEffect(() => {
        if (user?._id) {
            setDetails((oldDetail) => {
                return {
                    ...oldDetail,
                    first_name: user?.firstName,
                    last_name: user?.lastName,
                    mobile_number: user?.mobile_number,
                    email: user?.email,
                    id: user?._id
                }
            })
        }
    }, [user])


    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        var phonenoRegx = /^\d{10}$/;

        if (detail?.first_name === "") {
            toast.error('What\'s your first name?');
            return;
        }
        if (detail?.last_name === "") {
            toast.error('What\'s your last name?');
            return;
        }
        if (detail?.email === "") {
            toast.error('What\'s your email?');
            return;
        }

        if (detail?.mobile_number === "") {
            toast.error('What\'s your phone number?');
            return;
        } else if (detail?.mobile_number?.length !== 10 || !detail?.mobile_number?.match(phonenoRegx)) {
            toast.error('Invalid phone number?');
            return;
        }
        if (detail?.country_code === "") {
            toast.error('What\'s your country code?');
            return;
        }
        if (detail?.is_agree === false) {
            toast.error('Accept privacy & policy');
            return;
        }

        setErrorMessage("")
        dispatch(contactUs(detail))
            .then((res) => {
                // toast.success("We have received your request and our team connect you shortly.")
                navigate("/thank-you");
            }).catch((err) => {

            });

    }

    const onChangeNumber = (value: string) => {
        setDetails((oldDetail) => { return { ...oldDetail, mobile_number: value } })

    }
    const onChangeCountryCode = (value: string) => {
        setDetails((oldDetail) => { return { ...oldDetail, country_code: value } })
    }
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
        setDetails((oldDetail) => ({
            ...oldDetail,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value, type } = event.target;
        setDetails((oldDetail) => ({
            ...oldDetail,
            [name]: value
        }));
    };
    return (
        <form className="horizon_frm_contact_analyst" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6 mb-5">
                    <div className="form-floating">
                        <input type="text" className="form-control" placeholder="First Name *" name="first_name" value={detail?.first_name} onChange={handleOnChange} />
                        <label className="form-label" htmlFor="FormEmail1">First Name*</label>
                    </div></div><div className="col-md-6 mb-5">
                    <div className="form-floating">
                        <input type="text" className="form-control" placeholder="Last Name *" name="last_name" value={detail?.last_name} onChange={handleOnChange} />
                        <label className="form-label" htmlFor="FormEmail1">Last Name*</label>
                    </div></div></div><div className="row"><div className="col-md-12 mb-5">
                        <div className="form-floating">
                            <input type="email" className="form-control" placeholder="Business Email Address *" name="email" value={detail?.email} onChange={handleOnChange} />
                            <label className="form-label" htmlFor="FormEmail1">Business Email*</label>
                        </div>
                    </div>
                <div className="col-md-12 mb-2">
                    <CountryCodeInput onChangeNumber={onChangeNumber} disabled={user?.mobile_number ? true : false} defaultValue={user?.mobile_number} defaultCountryCode={user?.country_code ? user?.country_code : '+91'} onChangeCountryCode={onChangeCountryCode} inputClassName="h-50p"></CountryCodeInput>
                    <label htmlFor="phoneNumber" className="">
                    </label>
                </div>
            </div>
            <div className="mb-4">
                <div className="form-floating">
                    <textarea className="form-control" rows={3} placeholder="State your research requirements" name="description" onChange={handleTextChange}>
                    </textarea>
                    <label className="form-label" htmlFor="textarea">State your research requirements</label>
                </div>
            </div>
            <div className="mb-4">
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="flexCheckChecked" name="is_agree" value={`"${detail?.is_agree}"`} onChange={handleOnChange} />
                    <label className="form-check-label" htmlFor="flexCheckChecked">Yes, I have read the <a title="Privacy policy" href="https://www.grandviewresearch.com/info/privacy-policy">privacy policy</a></label>
                </div>
            </div>
            <div className="row d-flex align-items-center mb-4">
                <div className="col-3">
                    <button className="btn btn-primary-horizon btn-lg  text-none mt-4 mb-4 ms-0 me-0  white-space-nowrap" title="Submit"> {loader ? <Spinner /> : 'Submit'}   </button>
                </div>
            </div>
        </form>
    )
}