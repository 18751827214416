
import React, { useEffect, useState } from "react";
import DynamicMeta from "../../component/DynamicMeta";
import Layout from "../../layouts/dashboard/Layout";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCompanies } from "../../services/companyService";
import { pageRangeDisplayed } from "../../utils/constant";
import Pagination from "react-js-pagination";
import DataNotFound from "../../component/DataNotFound";
import { ShimmerTable } from "react-shimmer-effects";
import FilterBy from "../../component/FilterBy";

function CompanyTableView() {
    const dispatch = useAppDispatch();
    const param = useLocation();
     const [activePage, setActivePage] = useState(1);
    const { companies } = useSelector(({ company }) => company);
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const { filter ,refresh } = useSelector(({ common }) => common);

    useEffect(() => {
        let query = { q: params.get('search') || filter.company_name || '', page: activePage, limit: 10 };
        dispatch(getCompanies(query));
    }, [dispatch, param, activePage,refresh])


    const handlePageChange = (pageNumber: number) => {
        setActivePage(pageNumber)
    }

    return (
        <>
            <DynamicMeta />
            <Layout >
                <div className="hk-pg-wrapper pb-10">
                    <div className="container-fluid px-4">
                        <div className="hk-pg-body pt-2">
                            <section className="companies_sec">
                                <div className="row mt-4 justify-content-center">
                                    <FilterBy componentsOrder={["CompanySearch"]} />
                                    <div className="row mt-4 mt-lg-5">
                                        <div className="card card-border mb-4 table_layout2">
                                                {
                                                     companies?.loading ? <ShimmerTable row={10} col={7} /> :
                                                     <div className="table-responsive mt-0">
                                                    <table className="table">
                                                        <thead className="">
                                                            <tr>
                                                                <th scope="col">Company </th>
                                                                <th scope="col">HQ</th>
                                                                <th scope="col">Revenue</th>
                                                                <th scope="col">Employee</th>
                                                                <th scope="col">Operationg Status</th>
                                                                <th scope="col">IPO Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                companies?.data?.data?.map((data: any, index: number) => {
                                                                    return (
                                                                        <tr>
                                                                            <td style={{ width: "10%" }}><Link to={`/company-detail/${data?._id}`}><span className="fw-semibold mb-0">{data?.name}</span> </Link></td>
                                                                            <td style={{ width: "10%" }}><span>{
                                                                                data?.head_quarters?.map((hq)=>{
                                                                                    return(
                                                                                        `${hq.street_1} , ${hq.city} , ${hq.country} ,${hq.postal_code}.`
                                                                                    )
                                                                                })
                                                                                }</span></td>
                                                                            <td style={{ width: "10%" }}><span >
                                                                            {/* {data?.revenue_range} */}
                                                                            --
                                                                                </span></td>
                                                                            <td style={{ width: "10%" }}><span >
                                                                                {/* {data?.num_employees_enum} */}
                                                                                --
                                                                                </span></td>
                                                                            <td style={{ width: "10%" }}><span >{data?.operating_status ?.toUpperCase() || '--' }</span></td>
                                                                            <td style={{ width: "10%" }}><span >{data?.ipo_status?.toUpperCase() || '--'}</span></td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                (companies?.data?.data?.length <= 0 && !companies?.loading) && <tr ><td colSpan={5}><DataNotFound title="No results" description="Unfortunately, no related company could be found." /></td></tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                
                                                }
                                                
                                        </div>
                                        {

                                            <div className='mt-4'>
                                                <Pagination
                                                    activePage={activePage}
                                                    itemsCountPerPage={companies?.data?.per_page}
                                                    totalItemsCount={companies?.data?.total}
                                                    pageRangeDisplayed={pageRangeDisplayed}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    onChange={handlePageChange}

                                                />
                                            </div>
                                        }

                                    </div>

                                </div>

                            </section>
                        </div>
                    </div>
                </div>
            </Layout >
        </>
    )
}

export default CompanyTableView;
