import React from "react";

export const FeatherPersonCircleIcon = ({ color = "currentColor" }) => {
    return (<>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={color} className="bi bi-person-circle" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
        </svg>
    </>)
}


export const FeatherCheckSquareIcon = ({ color = "none" }) => {
    return (<>
        <svg path="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color} stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square">
            <polyline points="9 11 12 14 22 4"></polyline>
            <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
        </svg>

    </>)
}
export const FeatherMsgCirlceIcon = ({ color = "none" }) => {
    return (<>
        <svg path="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-circle">
            <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
        </svg>

    </>)
}
export const FeatherHelpCircleIcon = ({ color = "none" }) => {
    return (<>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={color} stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-help-circle"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>

    </>)
}
export const AvtarIcon = ({ color = "#09769F" }) => {
    return (<>
        <svg viewBox="0 0 24 24" width={20} height={20} xmlns="http://www.w3.org/2000/svg" fill={color}>
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> <g> <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M19.938 8H21a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-1.062A8.001 8.001 0 0 1 12 23v-2a6 6 0 0 0 6-6V9A6 6 0 1 0 6 9v7H3a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h1.062a8.001 8.001 0 0 1 15.876 0zM3 10v4h1v-4H3zm17 0v4h1v-4h-1zM7.76 15.785l1.06-1.696A5.972 5.972 0 0 0 12 15a5.972 5.972 0 0 0 3.18-.911l1.06 1.696A7.963 7.963 0 0 1 12 17a7.963 7.963 0 0 1-4.24-1.215z"></path>
            </g> </g>
        </svg>

    </>)
}


export const FeatherSettingIcon = ({ color = "none", width = "24", height = "24" }) => {
    return (<>
        <svg path="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill={color} stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-settings">
            <circle cx="12" cy="12" r="3"></circle>
            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
        </svg>

    </>)
}

export const SidebarToggleIcon = ({ color = "currentColor" }) => {
    return (<>
        <svg xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-arrow-bar-to-left" width="16" height="16"
            viewBox="0 0 16 16">
            <path fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
        </svg>
    </>)
}

export const HomeIcon = ({ color = "currentColor" }) => {
    return (<svg className="icon icon-tabler icon-tabler-layout"
        width="16" height="16" viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Icon/home-line">
            <path id="Vector (Stroke)" fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3861 1.21065C11.7472 0.929784 12.2528 0.929784 12.6139 1.21065L21.6139 8.21065C21.8575 8.4001 22 8.69141 22 9V20C22 20.7957 21.6839 21.5587 21.1213 22.1213C20.5587 22.6839 19.7957 23 19 23H5C4.20435 23 3.44129 22.6839 2.87868 22.1213C2.31607 21.5587 2 20.7957 2 20V9C2 8.69141 2.14247 8.4001 2.38606 8.21065L11.3861 1.21065ZM4 9.48908V20C4 20.2652 4.10536 20.5196 4.29289 20.7071C4.48043 20.8946 4.73478 21 5 21H19C19.2652 21 19.5196 20.8946 19.7071 20.7071C19.8946 20.5196 20 20.2652 20 20V9.48908L12 3.26686L4 9.48908Z"
                fill="#423547"></path>
            <path id="Vector (Stroke)_2" fillRule="evenodd"
                clipRule="evenodd"
                d="M8 12C8 11.4477 8.44772 11 9 11H15C15.5523 11 16 11.4477 16 12V22C16 22.5523 15.5523 23 15 23C14.4477 23 14 22.5523 14 22V13H10V22C10 22.5523 9.55228 23 9 23C8.44772 23 8 22.5523 8 22V12Z"
                fill="#423547"></path>
        </g>
    </svg>)
}

export const SettingIcon = () => {
    return (
        <svg path="http://www.w3.org/2000/svg" width="16"
            height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" strokeWidth="2"
            strokeLinecap="round" strokeLinejoin="round"
            className="feather feather-settings">
            <circle cx="12" cy="12" r="3"></circle>
            <path
                d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
            </path>
        </svg>
    )
}

export const SignOutIcon = () => {
    return (
        <svg stroke="currentColor"
            fill="currentColor" strokeWidth="0.8"
            viewBox="0 0 16 16" height="16" width="16"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 1v7h1V1h-1z"></path>
            <path
                d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z">
            </path>
        </svg>
    )
}

export const CompanyIcon = ({ color = "#423547", width = 16, height = 16 }) => {
    return (<>
        <svg width={width} height={height} viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Icon/building">
                <path id="Vector"
                    d="M19 3V21H13V17.5H11V21H5V3H19ZM15 7H17V5H15V7ZM11 7H13V5H11V7ZM7 7H9V5H7V7ZM15 11H17V9H15V11ZM11 11H13V9H11V11ZM7 11H9V9H7V11ZM15 15H17V13H15V15ZM11 15H13V13H11V15ZM7 15H9V13H7V15ZM15 19H17V17H15V19ZM7 19H9V17H7V19ZM21 1H3V23H21V1Z"
                    fill={color} />
            </g>
        </svg>
    </>)
}


export const WhitePapersIcon = ({ color = "#340f49" }) => {
    return (<>
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} width="16" height="16" viewBox="0 0 384 512"><path d="M64 464c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm56 256c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120z"></path></svg>

    </>)
}

export const DatabooksIcon = ({ color = "currentColor" }) => {
    return (<>
        <svg viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg" fill={color} strokeWidth="2" width="16" height="16"><defs></defs><path d="M27,22.1414V18a2,2,0,0,0-2-2H17V12h2a2.0023,2.0023,0,0,0,2-2V4a2.0023,2.0023,0,0,0-2-2H13a2.002,2.002,0,0,0-2,2v6a2.002,2.002,0,0,0,2,2h2v4H7a2,2,0,0,0-2,2v4.1421a4,4,0,1,0,2,0V18h8v4.142a4,4,0,1,0,2,0V18h8v4.1414a4,4,0,1,0,2,0ZM13,4h6l.001,6H13ZM8,26a2,2,0,1,1-2-2A2.0023,2.0023,0,0,1,8,26Zm10,0a2,2,0,1,1-2-2A2.0027,2.0027,0,0,1,18,26Zm8,2a2,2,0,1,1,2-2A2.0023,2.0023,0,0,1,26,28Z" /><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" fill="none" width="32" height="32" /></svg>
    </>)
}

export const SectorIcon = ({ color = "currentColor" }) => {
    return (<>
        <svg width="16" height="16" viewBox="0 0 24 24" id="icon" xmlns="http://www.w3.org/2000/svg"><g id="Icon/industry"><path id="Vector" d="M4 18V20H8V18H4ZM4 14V16H14V14H4ZM10 18V20H14V18H10ZM16 14V16H20V14H16ZM16 18V20H20V18H16ZM2 22V8L7 12V8L12 12V8L17 12L18 2H21L22 12V22H2Z" fill="#423547"></path></g></svg>
    </>)
}
export const CheckCircleIcon = ({ color = "currentColor" }) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill={color}
        className="bi bi-check-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z">
        </path> </svg>
}

export const MailBoxIcon = ({ color = "currentColor" }) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M18.3333 4.99992C18.3333 4.08325 17.5833 3.33325 16.6666 3.33325H3.33329C2.41663 3.33325 1.66663 4.08325 1.66663 4.99992V14.9999C1.66663 15.9166 2.41663 16.6666 3.33329 16.6666H16.6666C17.5833 16.6666 18.3333 15.9166 18.3333 14.9999V4.99992ZM16.6666 4.99992L9.99996 9.16658L3.33329 4.99992H16.6666ZM16.6666 14.9999H3.33329V6.66659L9.99996 10.8333L16.6666 6.66659V14.9999Z" fill="#351249"></path></svg>

}