import { Options } from 'highcharts';

// Utility function to merge objects deeply
const deepMerge = (target: any, source: any): any => {
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object && key in target) {
            Object.assign(source[key], deepMerge(target[key], source[key]));
        }
    }

    Object.assign(target || {}, source);
    return target;
};

// Common chart options
const commonChartOptions: Options = {
    colors: ['#418c9c', '#233b7d'],
    chart: {
        backgroundColor: 'transparent',
    },
    title: {
        text: ''
    },
    credits: {
        enabled: false,
    },
};

// Function to generate chart options
const getChartOptions = (type: string, customOptions: any = {}): Options => {
    let specificOptions: any = {};
    commonChartOptions.title.text = customOptions?.title;
    switch (type) {
        case 'area':
            specificOptions = {
                chart: { type: 'area', height: customOptions.height || '200px' },
                legend: {
                    enabled: customOptions.showLegend,
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'top',
                },
                yAxis: {
                    labels: { format: '{value}%' },
                    title: { enabled: false },
                },
                tooltip: {
                    pointFormat: customOptions.pointFormatter(),
                    split: true,
                },
                plotOptions: {
                    series: { pointStart: customOptions?.pointStart },
                    area: {
                        stacking: 'percent',
                        marker: { enabled: false },
                    },
                },
                series: customOptions.seriesData,

            };
            break;
        // 
        case 'column':
            specificOptions = {
                chart: { type: 'column', height: customOptions.height },
                legend: {
                    enabled: customOptions.showLegend,
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                },
                xAxis: {
                    categories: customOptions.XAxis,
                    labels: {
                        useHTML: true,
                        formatter: function () {
                            return this.axis.defaultLabelFormatter.call(this);
                        },
                    },
                    title: { text: customOptions?.title },
                },
                yAxis: {
                    labels: {
                        formatter: function () {
                            return this.axis.defaultLabelFormatter.call(this);
                        },
                    },
                    title: { text: '' },
                },
                // tooltip: { pointFormat: customOptions.pointFormatter() },
                series: customOptions.seriesData,
            };
            break;
        // 
        case 'line':
            specificOptions = {
                chart: { type: 'line' },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'top',
                },
                xAxis: {
                    categories: customOptions.XAxis?.categories,
                    labels: {
                        useHTML: true,
                        formatter: function () {
                            return this.axis.defaultLabelFormatter.call(this);
                        },
                    },
                    title: { text: customOptions.XAxis?.title?.text },
                },
                yAxis: {
                    labels: {
                        formatter: function () {
                            return this.axis.defaultLabelFormatter.call(this);
                        },
                    },
                    title: { text: customOptions.YAxis?.title?.text },
                },
                tooltip: {
                    pointFormatter: customOptions.pointFormatter,
                },
                series: structuredClone(customOptions.seriesData),
            };
            break;
        // 
        case 'pie':
            const totalPercentage = customOptions.seriesData.reduce((acc, item) => acc + item.y, 0);
            const blankSpace = 100 - totalPercentage;
            if (blankSpace > 0) {
                customOptions.seriesData.push({
                    name: 'Blank',
                    y: blankSpace,
                    disableTooltip: true,
                    color: 'transparent' // Make the blank space transparent
                });
            }
            specificOptions = {
                chart: { type: 'pie', height: customOptions.height },
                tooltip: {
                    formatter: function () {
                        if ((this.point as any).disableTooltip) {
                            return false;
                        }
                        return `<b>${this.point.name}</b>: ${this.point.percentage.toFixed()}%`;
                    },
                },
                plotOptions: customOptions.plotOptions,
                series: [{
                    name: 'Percentage',
                    colorByPoint: true,
                    innerSize: '60%',
                    data: customOptions.seriesData,
                }],
            };
            break;
        // 
        default:
            throw new Error(`Unsupported chart type: ${type}`);

    }

    return deepMerge(deepMerge({}, commonChartOptions), specificOptions);
};

export default getChartOptions;


export function FormatPatentGraphData(data : any) {
   
    const startYear = data.reduce((min : number, item : any) => item.year < min ? item.year : min, data[0]?.year);
    const endYear = data.reduce((max : number, item :any) => item.year > max ? item.year : max, data[0]?.year);
    
    // Ensure that startYear and endYear are properly set
    const validStartYear = startYear !== undefined ? startYear : null;
    const validEndYear = endYear !== undefined ? endYear : null;
    const transformedData = data.reduce((acc, { name, year, total }) => {
        // let newName = name !='design'? 'product' ;
        if (!acc[name]) {
            acc[name] = {};
        }
        acc[name][year] = total;
        return acc;
    }, {});    
    // Convert the transformed data to the desired format
    const result = Object.keys(transformedData).map((key, index) => {
        const yearlyData = [];
        for (let year = startYear; year <= endYear; year++) {
            const dataEntry = transformedData[key][year] || 0;
            yearlyData.push(dataEntry);
        }
        return {
            name: key,
            data: yearlyData
        };
    });
    result['startYear'] = startYear;
    result['endYear'] = endYear;

    return result
}
