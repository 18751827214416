import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { getUserDetails } from '../services/authService';

const PrivateLoginRoute = ({children}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate(); 

    useEffect(() => {
        dispatch(getUserDetails()).then((response) => {
            //Authorized user
            return navigate("/dashboard");
        }).catch((error) => {
            //Un-Authorized user
            // console.error("getUserDetails", error)
        });
    }, [dispatch,navigate])

    return ( <React.Fragment>{children}</React.Fragment>  );
};

export default PrivateLoginRoute;
