
import React, { useEffect, useState } from "react";
import DynamicMeta from "../../component/DynamicMeta";
import Layout from "../../layouts/dashboard/Layout";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCompanies } from "../../services/companyService";
import { formatDate, formatNumber, pageRangeDisplayed } from "../../utils/constant";
import Pagination from "react-js-pagination";
import { getSectorDropdown,  getSubSectorDropdown } from "../../services/sectorService";
import DataNotFound from "../../component/DataNotFound";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { CompanyData } from "../../types/companyTypes";

function CompanyList() {
    const dispatch = useAppDispatch();
    const [activePage, setActivePage] = useState(1);
    const { companies } = useSelector(({ company }) => company);
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);

    useEffect(() => {
        let query = { q: params.get('search') || '', page: activePage, limit: 10 };
        dispatch(getCompanies(query));
        // dispatch(getSectorDropdown({ q: '', companyId: '', limit: 10 }));
        // dispatch(getSubSectorDropdown({ q: '', parentId: '', companyId: '', limit: 10 }));
    }, [dispatch,params.get('search'), activePage])


    const handlePageChange = (pageNumber: number) => {
        setActivePage(pageNumber)
    }
   
    return (
        <>
            <DynamicMeta />
            <Layout >
                <div className="hk-pg-wrapper pb-10">
                    <div className="container-fluid px-4">
                        <div className="hk-pg-body pt-2">
                            <section className="companies_sec">
                                <div className="row mt-4 justify-content-center">
                                    <div className="row mt-4 mt-lg-5">
                                        
                                        <div className="col-lg-8">
                                            <div className="companies_sec">
                                                <header className="mb-5">
                                                    <h1 className="decor-alpha fw-bold h3">Search Results </h1>
                                                    <p>{ (companies?.data.total && formatNumber(companies?.data.total)) || 0} results {params.get('search') && (<><span>for</span> <Link to={'/search?search='} className="text-underline mt-2">Clear</Link> </>)}</p>
                                                </header>
                                                {/* <h5 className="fw-semibold mb-4">{params.get('search')}, ESG Risk Rating Overview</h5> */}
                                                {
                                                    companies?.loading ? Array(10).fill(<ShimmerThumbnail height="150" />) :
                                                        companies?.data?.data?.map((data: CompanyData, index: number) => {
                                                            return (
                                                                <div className="mb-4 border rounded p-3 bg-white"><div className="row" key={`company-row-${index}`}>
                                                                    <div className="col-lg-8">
                                                                        <Link to={`/company-detail/${data?._id}`}><h5 className="fw-semibold mb-0">{data?.name}, ESG Risk Rating Overview</h5> </Link>
                                                                        <div className="d-flex mt-2 text-secondary">
                                                                            <span className="mr-4">Sector : {data?.category?.parent?.name || '--'}  </span>
                                                                            <span className="ml-4">Subsector : {data?.category?.name || '--'} </span>
                                                                        </div>
                                                                        <span className="mt-2 text-secondary">Update On : {formatDate(data?.updatedAt) || "--"}</span>
                                                                    </div>
                                                                </div></div>
                                                            )
                                                        })
                                                }
                                                {(companies?.data?.data?.length <= 0 && !companies?.loading) && <DataNotFound title="No results" description="Unfortunately, no related search keyword could be found." />}


                                            </div>
                                            {
                                                
                                                <div className='mt-4'>
                                                    <Pagination
                                                        activePage={activePage}
                                                        itemsCountPerPage={companies?.data?.per_page}
                                                        totalItemsCount={companies?.data?.total}
                                                        pageRangeDisplayed={pageRangeDisplayed}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                        onChange={handlePageChange}

                                                    />
                                                </div>
                                            }
                                        </div>

                                    </div>

                                </div>

                            </section>
                        </div>
                    </div>
                </div>
            </Layout >
        </>
    )
}

export default CompanyList;
