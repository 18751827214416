import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { getUserDetails } from "../services/authService";


function AuthenticatedRoute({ children }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
        dispatch(getUserDetails()).then((response)=>{
           //Authorised User
        }).catch((error)=>{
          //Unauthorised User
          return navigateToLogin();
        });
   }, [dispatch]);

  // Function to navigate to the login page
  const navigateToLogin = () => {
    localStorage.setItem('previousRoute', window.location.pathname + window.location.search);
    navigate("/sign-in#")
  };
  return <> {children}</>;
  
};

export default AuthenticatedRoute;
