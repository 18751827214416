import { CommonResponseState } from "../types/commonTypes";
import { AppDispatch } from "../store";
import { setEsgScore, setLoading, setSectorDetails, setSectors, setSectorsDropdown, setSubSectorsDropdown } from "../store/sectorsSlice";
import { fetcherGet } from "../utils/fetcher";
import { toast } from "react-toastify";

export function getSubSectorDropdown({ q, parentId, companyId, limit }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'subSectorsDropdown', loading: true }));
        fetcherGet(`sub-industry-dropdown?q=${q}&company_id=${companyId}&parent_id=${parentId}&limit=${limit}`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setSubSectorsDropdown({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {

            }).finally(() => {
                dispatch(setLoading({ key: 'subSectorsDropdown', loading: false }));
            });
    };
}

export function getSectorDropdown({ q, companyId, limit }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'sectorsDropdown', loading: true }));
        fetcherGet(`parent-industry-dropdown?q=${q}&company_id=${companyId}&limit=${limit}`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setSectorsDropdown({ data: response?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'sectorsDropdown', loading: false }));
            });
    };
}

export function getSectors({ q, limit }) {
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'sectors', loading: true }));
        fetcherGet(`industries`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setSectors({ data: response?.data?.data, loading: false }));
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'sectors', loading: false }));
            });
    };
}

export function getSectorsDetails(sectorId: string) {
    let currentYear = new Date().getFullYear() - 1;
    return async (dispatch: AppDispatch) => {
        dispatch(setLoading({ key: 'sectorDetail', loading: true }));
        dispatch(setLoading({ key: 'esgScore', loading: true }));
        fetcherGet(`industry/${sectorId}?year=${currentYear}`)
            .then((response: CommonResponseState) => {
                if (response.status === 200) {
                    dispatch(setSectorDetails({ data: response?.data[0], loading: false }));
                    dispatch(setEsgScore(response?.esgScore))
                }
            })
            .catch((error) => {
                // toast.error(error?.response?.data?.error || error?.message)

            }).finally(() => {
                dispatch(setLoading({ key: 'sectorDetail', loading: false }));
                dispatch(setLoading({ key: 'esgScore', loading: false }));

            });
    };
}
