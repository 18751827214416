
import React, { useEffect, useState } from "react";
import DynamicMeta from "../../component/DynamicMeta";
import Layout from "../../layouts/dashboard/Layout";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import thumbnail_logo from "../../assets/img/thumbnailImage.png"
import FilterBy from "../../component/FilterBy";
import Monitors from "../../component/Monitor";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCompanyDetail } from "./../../services/companyService";
import PatentRow from "../../component/Rows/Patent";
import JobRow from "../../component/Rows/Jobs";
import DealsRow from "../../component/Rows/Deals";
import NewsRow from "../../component/Rows/News";
import { formatDate } from "../../utils/constant";
import { RiskRating } from "../../component/RiskRating";
import { ShimmerTitle } from 'react-shimmer-effects';
import EsgDiscloser from "../../component/EsgDiscloser";
import TopEsgStories from "../../component/TopEsgStories";
import RegulationRow from "../../component/Rows/RegulationRow";
function CompanyDetail() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    const { companyId } = useParams();
    const [page, setPage] = useState('');
    const { company } = useSelector(({ company }) => company);
    const defaultComponentsOrder = ['CompanyFilter', 'SectorFilter', 'SubSectorFilter'];
    const [componentsOrder, setComponentsOrder] = useState(defaultComponentsOrder);
    const [filterBy, setFilterBy] = useState('Company');

    useEffect(() => {
        switch (page) {
            case 'patent':
                setComponentsOrder(['KeywordFilter', 'CompanyFilter']);
                setFilterBy("Patent")
                break;
            case 'deals':
                setComponentsOrder(['CompanyFilter', 'SectorFilter', 'SubSectorFilter']);
                setFilterBy("Deals")
                break;
            case 'jobs':
                setComponentsOrder(['CompanyFilter', 'SectorFilter', 'SubSectorFilter', 'FromYearFilter', 'ToYearFilter']);
                setFilterBy("Jobs")
                break;
            case 'regulations':
                setComponentsOrder(['KeywordFilter', 'RegionFilter', 'CountryFilter']);
                setFilterBy("Regulations")
                break;
            case 'news':
                setComponentsOrder(['CompanyFilter', 'SectorFilter', 'FromYearFilter', 'ToYearFilter']);
                setFilterBy("News")
                break;
            default:
                setFilterBy("Company")
                break;
        }
    }, [page]);
    useEffect(() => {
        dispatch(getCompanyDetail(companyId));
    }, [dispatch, companyId])

    const handleTabChange = (page: string) => {
        // setPage(page)
        if(page == ''){
            navigate("#overview");
        }else{
            navigate("#" + page);
        }
    }

    const {hash, key} = useLocation()
useEffect(()=>{
    if(hash){
       const targetElement = document.getElementById(hash.substring(1))
        targetElement?.scrollIntoView({behavior: 'smooth'})
    }
}, [key, hash])
    return (
        <>
            <DynamicMeta />
            <Layout >
                <div className="hk-pg-wrapper pb-10">
                    <div className="container-fluid px-4">
                        <div className="hk-pg-body pt-2">
                            {/* <FilterBy filterBy={filterBy} componentsOrder={componentsOrder} /> */}
                            <Monitors handleTabChange={handleTabChange} />
                            <section className="companies_sec">
                                <div className="row mt-4 justify-content-start">
                                    <div className="col-lg-9">
                                        <div className="row" id="overview">
                                            <div className="col-lg-1">
                                                <img src={company?.data?.image_url} className="img-fluid" width="100" height="42" alt="logo" />
                                            </div>
                                            <div className="col-lg-6">
                                                <h5 className="fw-semibold mb-0">{company?.data?.name ? `${company?.data?.name} ESG Risk Rating Rating Overview` : <ShimmerTitle line={1} gap={10} variant="primary" />} </h5>
                                                <div className="mt-3">
                                                    <p ><b>Overview</b> <br /> <span className="text-secondary">{company?.data?.description || "--"}</span></p>
                                                </div>
                                                <div className="d-flex mt-3 mb-2 justify-content-between">
                                                    <p><b>Operting Status</b> <br /> <span className="mt-4 text-secondary">{company?.data?.operating_status || "--"}</span></p>
                                                    <p><b>HQ</b> : <br /> {company?.data?.head_quarters ? <span className="mt-4 text-secondary">{company?.data?.head_quarters && company?.data?.head_quarters[0].street_1}, {company?.data?.head_quarters && company?.data?.head_quarters[0].city}, {company?.data?.head_quarters && company?.data?.head_quarters[0].country} , {company?.data?.head_quarters && company?.data?.head_quarters[0].postal_code} </span> : '--'}</p>
                                                </div>
                                                <div className="d-flex mt-3 mb-2 justify-content-between">
                                                    <p><b>Legal Name</b> <br /> <span className="mt-4 text-secondary">{company?.data?.legal_name || "--"}</span></p><br />
                                                </div>
                                            </div>
                                            <div className="col-lg-1"></div>
                                            <div className="col-lg-4 text-start">
                                                <div className="mb-2">
                                                    <p><b>Sector</b> <br /> <span className="mt-4 text-secondary">{company?.data?.category?.parent?.name || "--"}</span></p>
                                                </div>
                                                <div className="mb-2">
                                                    <p><b>Industry</b> <br /> <span className="mt-4 text-secondary">{company?.data?.category?.name || "--"}</span></p>
                                                </div>
                                                <div className="mb-2">
                                                    <p><b>Employment Strength</b> <br /> <span className="mt-4 text-secondary">{company?.data?.num_employees_enum || "--"}</span></p>
                                                </div>
                                                <div className="mb-2">
                                                    <p><b>Website</b> <br /> <span className="mt-4 text-secondary">{company?.data?.website_url || "--"}</span></p>
                                                </div>
                                                <div className="mb-2">
                                                    <p><b>Phone</b> <br /> <span className="mt-4 text-secondary">{company?.data?.phone_number || "--"}</span></p>
                                                </div>
                                               </div>
                                            {
                                                page === '' && <>
                                                    <div className="col-lg-12">
                                                        <div className="companies_sec">
                                                            <RiskRating lastUpdate={company?.data?.updatedAt} esgScore={company?.data?.esgScore?.all_score || 0} scopeChart={company?.data?.scopeTotalChart || null} />
                                                            {/* <EsgDiscloser esgScore={company?.data?.esgScore} /> */}
                                                        </div>
                                                    </div>


                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-12 col-sm-6">
                                        <TopEsgStories />
                                    </div>

                                </div>
                                <div className="row mt-4 mt-lg-5">
                                    <div className="col-lg-12">
                                        <div className="companies_sec">
                                            <DealsRow pagination={false} />
                                            <PatentRow pagination={false} />
                                            <JobRow pagination={false} />
                                            <RegulationRow  pagination={false} />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div >
                </div >
            </Layout >
        </>
    )
}

export default CompanyDetail;
