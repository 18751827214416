import React, { Suspense, useEffect } from "react";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getUserDetails } from "../../services/authService";

function LandingLayout({ children }) {
    const dispatch = useAppDispatch();
   useEffect(()=>{
    dispatch(getUserDetails()).then(()=>{}).catch(()=>{});
   },[dispatch])
     return (
        <>
            <Suspense>
                <LandingHeader />
                {children}
                <LandingFooter />
            </Suspense>
        </>
    );
}

export default LandingLayout;
