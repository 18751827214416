import { PayloadAction, createSlice, } from "@reduxjs/toolkit";
import { UserData } from "../types/authTypes";

const initialState = {
    user:null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserDetails: (state, action: PayloadAction<UserData>) => {
            state.user = action.payload;
        }
    },
});

export const { setUserDetails } = authSlice.actions;
export default authSlice.reducer;


