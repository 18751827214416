
import Cookies from 'js-cookie';
import apiClient from "./app-client"
import { unAuthenticatedRoute } from './constant';


export default async function fetcher(
  request: string,
  method = "GET",
  data: any,
  baseUrl = process.env.REACT_APP_API_URL,
) {
  const options = {
    url: `${baseUrl}/${request}`,
    method,
    data,

  };
  await csrfGeneratee();
  return new Promise((resolve, reject) => {
    apiClient(options)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (!error.data) {
          error.data = { message: error.message };
        }
        reject(error);
      });
  })
}


export async function fetcherPost(
  request: string,
  data: any,
  method = "Post",
  baseUrl = process.env.REACT_APP_API_URL,
) {
  const options = {
    url: `${baseUrl}/api/${request}`,
    method,
    data
  };
  await csrfGeneratee();

  return new Promise((resolve, reject) => {
    apiClient(options)
      .then((res) => {
        resolve(res?.data);
      })
      .catch((error) => {
        if (!error?.data) {
          error.data = { message: error?.message };
        }
        if (error?.response?.status === 401 && unAuthenticatedRoute?.includes(window.location.href)) {
          window.location.replace("/sign-in")
        }
        reject(error);
      });
  })

}


export async function fetcherGet(
  request: string,
  method = "GET",
  baseUrl = process.env.REACT_APP_API_URL,
) {
  const options = {
    url: `${baseUrl}/api/${request}`,
    method,

  };

  return new Promise((resolve, reject) => {
    apiClient(options)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (!error.data) {
          error.data = { message: error.message };
        }
        if (error?.response?.status === 401 && unAuthenticatedRoute?.includes(window.location.href)) {
          window.location.replace("/sign-in")
        }
        reject(error);
      });
  })

}
export async function fetcherPut(
  request: string,
  data: any,
  method = "Put",
  baseUrl = process.env.REACT_APP_API_URL,
) {
  const options = {
    url: `${baseUrl}/api/${request}`,
    method,
    data
  };
  await csrfGeneratee();
  return new Promise((resolve, reject) => {
    apiClient(options)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (!error.data) {
          error.data = { message: error.message };
        }
        if (error?.response?.status === 401 && unAuthenticatedRoute?.includes(window.location.href)) {
          window.location.replace("/sign-in")
        }
        reject(error);
      });
  })

}


export async function csrfGeneratee() {
  if (Cookies.get('XSRF-TOKEN') === undefined) {
    return new Promise((resolve, reject) => {
      apiClient.get('/sanctum/csrf-cookie').then(response => {
        resolve(response)
      }).catch((error) => {
        reject({ error: error })
      })
    });
  }
}