import React from "react";
import UpdatePasswordForm from "../../component/Forms/UpdatePasswordForm";
import SignUpLayout from "./SignUpLayout";

const UpdatePassword = () => {


    return (
        <>
            <SignUpLayout>
                <UpdatePasswordForm />
            </SignUpLayout>
        </>
    );
};

export default UpdatePassword;
