
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {  setPageTitle } from "../../store/commonSlice";
import { validateEmail } from "../../utils/constant";
import { signUp } from "../../services/authService";
import { ButtonSpinner } from "../../component/Button";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { FaEye, FaEyeSlash } from "react-icons/fa";


export default function SignupForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    dispatch(setPageTitle("NeoImpact - Sign Up"))
  }, [dispatch])

  const { loader } = useSelector(({ common }) => common);
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    privacyPolicy: true
  };
  const [detail, setDetails] = useState(defaultValues);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage("");
    if (detail?.firstName === "") {
      setErrorMessage('What\'s your first name?');
      return;
    }
    if (detail?.lastName === "") {
      setErrorMessage('What\'s your last name?');
      return;
    }
    if (detail?.email === "") {
      setErrorMessage('What\'s your email?');
      return;
    }
    if (!validateEmail(detail?.email)) {
      setErrorMessage('Please enter the valid email address');
      return;
    }
    if (detail?.password === "") {
      setErrorMessage('Provide your password');
      return;
    }
    if (!detail?.privacyPolicy) {
      setErrorMessage('Accept term and condition ');
      return;
    }
    setErrorMessage("")
    dispatch(signUp(detail))
      .then((res) => { })
      .catch((err) => {
        console.log("error", err)
      });


  }



  const handleOnChange = (event:ChangeEvent<HTMLInputElement>) => {
   
    let {name,value,type} = event.target
    if (type === 'checkbox') {
      let value = event.target.checked;
      setDetails((oldDetail) => { return { ...oldDetail, [name]: value } })
    } else {
      setDetails((oldDetail) => { return { ...oldDetail, [name]: value } })
    }
  }
  
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };


  return (
    <>
      <form data-gtm-form-interact-id="0" onSubmit={handleSubmit}>
        <div className="row">
          <div className="row gx-3">
            {(errorMessage) && <p className='text-danger p-xs mb-2 text-center'>{errorMessage}</p>}
          </div>
          <div className="col-lg-6">
            <div className="form-floating mb-4">
              <input
                className="form-control signup-hover"
                placeholder="First Name *"
                type="text"
                name="firstName"
                id="floatingFirstNameInput"
                onChange={handleOnChange}
                value={detail?.firstName}
              />
              <label>First Name</label>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-floating mb-4">
              <input
                className="form-control signup-hover"
                placeholder="Last Name *"
                name="lastName"
                type="text"
                id="floatingLastNameInput"
                onChange={handleOnChange}
                value={detail?.lastName}
              />
              <label>Last Name</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="form-floating mb-4">
              <input
                className="form-control signup-hover"
                placeholder="Business Email Address *"
                name="email"
                type="text"
                value={detail?.email} required onChange={handleOnChange}
                data-gtm-form-interact-field-id="0"
              />
              <label>Business Email Address</label>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-floating mb-4" style={{ position: "relative" }}>
              <input type={showPassword ? 'text' : 'password'} name="password" className="form-control" id="floatingPassword"
                placeholder="Password" value={detail?.password} required onChange={handleOnChange} />
              <Link to="#" onClick={handleTogglePassword} className="input-suffix text-muted" title={showPassword ? "Hide Password" : "View Password"} style={{ position: "absolute", top: "16px", right: "5px" }}>
                {detail?.password !== "" && (showPassword ? <FaEyeSlash /> : <FaEye />)}
              </Link>
              <label>Password</label>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="mb-4">
              <div>
                <div>
                  <input
                    type="hidden"
                    name="cf-turnstile-response"
                    id="cf-chl-widget-qd6t9_response"
                    value="0.SB9Yxmu8hkDHCCPK_UYvPnQp0lO4UA-fqvHKNSdJj-pES9eQnkpeX4C6dPiZgv-DyNkvfKbomfJCrQ1_J7H0OoBEJnvXGJ8OAree1XEF2nP5Nbx7RqojK7gevpF3y6Fshnhch70JqObrayRw5_vwVD7gQH9jmv133Y8MPW9KuEi2RifdkUThz_dXf3ezYGL3jSrfx-Fo-i8DF69yphsz_34GjZ8y0Xd1Sk8r1OXDw8RJpA0Ml32um4B0DcNTLlhtI_S6Nps23TzdJ7q5ygmKxm4vOq03M5C-hFZ5rE8bsPuqILmUNicIgeEpyjzli1Wi7Wxa2TS6jMJjcEx_K6H55RmdCjUamaWEmXgkhbN27lA71SVDPOmfr_xM0Hm7mFJJbCrYV4mwBWPjC5Zx4oLxuu6zGRVoQnAlQZc5HH2nlgTZ-BEQhoZfc886k-wZne4eJ6fNIhNZWqXkkhcz6ULwvzkOl_aM63C3GSfYj2zExN0H9THbaKHuh907ISgKv1SzZ6RJ095tYvHwTITE9SflCdWtB0kg9S1cOJeSf8D5D1I3uC_zomyEscPwZycq9X05sbE7t8ycLw6A2EtxFYgiN9SY16_oz9Y3b9_Fxc6LrKbQ-uO8uJRSkTDnyxYjVcF1ovzyvXLzAeqE0aNksAtby6ZB7-iV4QHxT-DTpNlJRX2RuwqTgVx958j_IdxRkggO4I_InIzroNe8u8blbXA0MgfFwzq99ik81qaSJUjIOWMYKRZXa3VYm_GYPLlpL1vh.hLRMHr8Jf9De7hd3CT6k2w.57d448ccf2242e6fcc05855259a0ecd78e1188308a8231ed562daa7da5e5ecaa"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-check form-check-lg">
              <input
                type="checkbox"
                className="form-check-input"
                id="privacyPolicy"
                name="privacyPolicy"
                onChange={handleOnChange}

                checked={detail?.privacyPolicy}
              />
              <label className="form-check-label text-muted fs-7 mt-1">
                I accept the{" "}
                <a
                  title="Privacy Policy"
                  rel="noreferrer"
                  href="https://www.grandviewresearch.com/info/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a
                  title="Terms of Services"
                  rel="noreferrer"
                  href="https://www.grandviewresearch.com/info/terms-of-use"
                  target="_blank"
                >
                  Terms of Services
                </a>
                .
              </label>
            </div>
          </div>
        </div>
        <button
          className="btn btn-primary-horizon btn-lg w-100 text-none mt-4 mb-4 ms-0 me-0           white-space-nowrap"
          title="Create free account"
          disabled={loader}
        >
          <ButtonSpinner />
          Create free account{" "}
        </button>
        <p className="text-center">
          Already have an account?{" "}
          <a href={"#"} onClick={() => navigate("/sign-in#")} className="text-decoration-underline">
            Sign in
          </a>{" "}
          here.{" "}
        </p>
      </form>
 
    </>
  )
}