import React, { useEffect } from "react";
import { setPageTitle } from "../../store/commonSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import ForgotPasswordForm from "../../component/Forms/ForgotPasswordForm";
import SignUpLayout from "./SignUpLayout";


function ForgotPassword() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setPageTitle("NeoImpact - Forgot Password"));
    }, [dispatch]);

    return (
        <>

            <SignUpLayout>
                <ForgotPasswordForm />
            </SignUpLayout>
        </>
    );
};

export default ForgotPassword;
